import { FC } from 'react'
import Sidebar from '../../components/layout/Sidebar'
import { Outlet } from 'react-router-dom'
import Header from 'components/layout/Header'
import SearchProvider from 'contexts/Search/SearchProvider'
import PnlSyncProvider from 'contexts/PnlSync'
import OutboundSyncProvider from 'contexts/OutboundSync'

const MainLayout: FC = () => {
  return (
    <PnlSyncProvider>
      <OutboundSyncProvider>
        <SearchProvider>
          <div className="h-full flex w-full overflow-hidden">
            <Sidebar
              buttons={[
                // { icon: 'PnL', name: 'P&L', path: '/app/pnl' },
                { icon: 'Inventory', name: 'Inventory', path: '/app/catalog' },
                { icon: 'Restocking', name: 'Restocking', path: '/app/restocking' },
                {
                  icon: 'CreditCard',
                  name: 'Expenses',
                  path: '/app/expenses/prep-cost',
                  subRoutes: [
                    /*{
                      icon: 'CreditCard',
                      name: 'Expenses',
                      path: '/app/expenses',
                    },*/
                    {
                      icon: 'PrepCost',
                      name: 'Prep Cost',
                      path: '/app/expenses/prep-cost',
                    },
                  ],
                },
                {
                  icon: 'Suppliers',
                  name: 'Suppliers',
                  path: '/app/suppliers',
                  subRoutes: [
                    {
                      icon: 'SupplierSettings',
                      name: 'Supplier Accounts',
                      path: '/app/suppliers/settings',
                    },
                    {
                      icon: 'SupplierLeads',
                      name: 'Supplier Leads',
                      path: '/app/suppliers/leads',
                    },
                  ],
                },
                {
                  icon: 'POs',
                  name: 'Purchase Orders',
                  path: '/app/purchase-orders',
                  subRoutes: [
                    {
                      icon: 'WorkingPOs',
                      name: 'Working',
                      path: '/app/purchase-orders/working',
                    },
                    {
                      icon: 'POConfirmation',
                      name: 'Confirmation',
                      path: '/app/purchase-orders/open',
                    },
                    {
                      icon: 'CompletedPOs',
                      name: 'Completed',
                      path: '/app/purchase-orders/closed',
                    },
                  ],
                },
                {
                  icon: 'Warehouse',
                  name: 'Warehouse',
                  path: '/app/warehouse',
                  subRoutes: [
                    { icon: 'Truck', name: 'Inbound', path: '/app/warehouse/inbound' },
                    { icon: 'Warehouse', name: 'Inventory', path: '/app/warehouse/inventory' },
                    { icon: 'TruckOut', name: 'Outbound', path: '/app/warehouse/outbound' },
                  ],
                },
                {
                  icon: 'InternalDatabase',
                  name: 'Product Database',
                  path: '/app/housed',
                  subRoutes: [
                    { icon: 'MagnifyingGlass', name: 'ASIN Asset Watchlist', path: '/app/housed/housed' },
                    { icon: 'Barcode', name: 'Supply Chain Scanner', path: '/app/housed/master-catalog' },
                    { icon: 'Buyers', name: 'Buyers', path: '/app/housed/buyers' },
                    //{ icon: 'CheckSquareOffset', name: 'Asin Entry', path: '/app/housed/asin-entry' },
                  ],
                },
              ]}
            />
            <div className="flex flex-col grow h-full overflow-hidden">
              <div className="flex">
                <Header />
              </div>
              <div className="flex grow w-full overflow-y-auto">
                <Outlet />
              </div>
            </div>
          </div>
        </SearchProvider>
      </OutboundSyncProvider>
    </PnlSyncProvider>
  )
}

export default MainLayout
