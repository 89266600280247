import React, { useEffect, useRef } from "react";
import jsBarcode from "jsbarcode";

interface BarcodeProps {
    upcCode: string;
}

const Barcode: React.FC<BarcodeProps> = ({ upcCode }) => {
    const barcodeRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        if (barcodeRef.current) {
            const upcCodeString = (upcCode || "000000000000").toString();
            jsBarcode(barcodeRef.current, upcCodeString, {
                format: "UPC",
                displayValue: true,
                marginTop: 1,
                margin: 2,
                width: 1.5,
                height: 20,
                fontSize: 12,
                textMargin: 0,
            });
        }
    }, [upcCode]);

    return <canvas ref={barcodeRef}></canvas>;
};

export default Barcode;
