import { Dispatch, PropsWithChildren, SetStateAction, forwardRef, useCallback, useMemo } from 'react'
import SuppliersContext from './suppliersContext'
import { Supplier } from 'types/suppliers'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import suppliersApi from 'api/suppliers'
import withFiltering from 'contexts/Filter/wrapper'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { IApiFilter } from 'types/tableFiltering'

export interface IConnectedClient {
  id: string
  client: string
}

const queryFn = async ({pageParam: page, queryKey}: {pageParam: number, queryKey: [string, IApiFilter]}) => {
  return suppliersApi.getSuppliers({page, ...queryKey[1]})
}

const SuppliersProvider = forwardRef<{}, PropsWithChildren>(({ children }, forwarded) => {
  const filter = useFilteringContext()
  const {search, ordering, opFilters: filters} = filter

  const queryKey = useMemo(() => (
    [
    'suppliers',
    {
      search,
      ordering,
      filters
    }
    ] as [string, IApiFilter]
  ), [search, ordering, filters])

  const {data, refetch, fetchNextPage, isFetching, isRefetching, isFetchingNextPage, hasNextPage: hasMore} = useInfiniteQuery(
    {
      queryKey: queryKey,
      queryFn,
      initialPageParam: 1,
      getNextPageParam: (lastPage) => lastPage.next,
    }
  )

  const loading = isFetching || isRefetching
  const loadingNextPage = isFetchingNextPage

  const items = useMemo(() => data ? [...data.pages.flatMap((p) => p.suppliers)] : undefined, [data])

  const qc = useQueryClient()

  const setItems: Dispatch<SetStateAction<Supplier[] | undefined>> = useCallback((action) => {
    qc.setQueryData(queryKey, (old: typeof data) => {
      if (!old) return old
      if (action instanceof Function){
        return {
          ...old,
          pages: old.pages.map((p) => ({
            ...p,
            suppliers: action(p.suppliers)
          }))
        }
      } if (!action) {
        refetch()
      } else {
        return {
          ...old,
          pages: old.pages.map((p, i) => ({
            ...p,
            suppliers: action[i]
          }))
        }
      }
    })
    qc.invalidateQueries({queryKey: ['supplierNames']})
    qc.invalidateQueries({queryKey: ['supplierWebsites']})
  }, [qc, queryKey, refetch])

  return <SuppliersContext.Provider value={{ suppliers: items, setSuppliers: setItems, loading, loadingNextPage, loadNextPage: fetchNextPage, hasMore}}>{children}</SuppliersContext.Provider>
})

export default withFiltering(SuppliersProvider, "suppliers-provider")
