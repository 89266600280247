import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import { useCallback, useMemo } from 'react'
import { EntityWithKeepaStatColumns } from 'types/keepaStats'
import { RowRenderer } from 'types/tables'
import { usNumber } from 'utils/formatting'
import { keepaStatsTableDef } from './tableDef'
import { Cell } from '@tanstack/react-table'

const keys = keepaStatsTableDef.map((column) => column.id).filter((key) => !!key) as string[]

const useKeepaStatsRenderer = () => {
  const singleRenderer = useCallback((cell: Cell<EntityWithKeepaStatColumns, EntityWithKeepaStatColumns>) => (
      <td key={cell.id} className="!py-1.5 !px-3" style={{ ...getCommonPinningStyles(cell.column) }}>
        <input type="text" readOnly className="table-input" value={usNumber(cell.row.original[cell.column.id as keyof EntityWithKeepaStatColumns], 0)} placeholder="0" />
      </td>
  ), [])

  const renderer: RowRenderer<EntityWithKeepaStatColumns> = useMemo(() => keys.reduce((acc, key) => ({
    ...acc,
    [key]: singleRenderer,
  }), {}), [singleRenderer])
  
  return renderer
}

export default useKeepaStatsRenderer
