import useTableContext from 'contexts/Table/useTableContext'
import { FC, useMemo } from 'react'
import TR from './tables/TR'

interface props {
  rows?: number
}

const TableSpanner: FC<React.PropsWithChildren<props>> = ({ rows = 1, children }) => {
  const {table} = useTableContext()
  const cols = useMemo(() => table.getAllColumns().length, [table])
  return (
        <TR className="!bg-white w-full grow !h-full">
          <td className="w-full loader" colSpan={cols} style={{ height: rows * 64}}>
            {children}
          </td>
        </TR>
  )
}

export default TableSpanner
