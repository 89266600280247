import { ColumnDef } from '@tanstack/react-table'
import { SupplierLead } from 'types/supplierLeads'

const columnDef: ColumnDef<SupplierLead>[] = [
  {
    id: 'name',
    header: 'Supplier Name',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'website',
    header: 'Website',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'email',
    header: 'Contact Email',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'phone',
    header: 'Contact Phone',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'industry',
    header: 'Industry',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'state',
    header: 'State / Area',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'notes',
    header: 'Notes',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'contactFirst',
    header: 'Contact First',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'dateOfContact',
    header: 'Date Of Contact',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'contactMethod',
    header: 'Contact Method',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'conversation',
    header: 'Details About Convo',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'outcome',
    header: 'Opened / Declined',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'dateOfNextContact',
    header: 'Date Of Next Contact',
    meta: {
      filters: 'date',
    },
  },
]

export default columnDef
