import amazonItemImgUrl from "helpers/amazonItemImgUrl"
import useAmazonProductImage from "hooks/useAmazonProductImage"
import { DetailedHTMLProps, FC, ImgHTMLAttributes } from "react"

interface Props extends Omit<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, "src"> {
    asin: string,
}

const FullAmazonProductImage: FC<Props> = ({asin, className, style, ...props}) => {
    const image = useAmazonProductImage(asin)
    const url = amazonItemImgUrl(image?.url, 500, false)

    if (!image) return <div className={[className, "bg-slate-200 rounded animate-pulse"].asClass} style={style} {...props} />

    return (
        <img alt={props.alt || asin} src={url} className={className} style={style} {...props} />
    )
}

export default FullAmazonProductImage