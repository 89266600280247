import { FC, useCallback, useDeferredValue, useMemo, useState } from 'react'
import ContentLayout from 'components/layout/Content'
import columnDef from './tableDef'
import Table from 'components/tables/Table'
import withFiltering from 'contexts/Filter/wrapper'
import useExpenses from 'hooks/useExpenses'
import { IExpense } from 'types/expenses'
import expensesApi from 'api/expenses'
import useAwaitableModal from 'hooks/useAwaitableModal'
import CreateFormModal from 'components/modals/CreateForm'
import useSelect from 'hooks/useSelect'
import Expense from './components/Expense'
import useBulkDelete from 'hooks/useBulkDelete'
import MonthHeader from './components/MonthHeader'
import PeriodFilter from 'components/PeriodFilter'
import Graphs from './components/Graphs'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import Icon from 'assets/icons/iconset'
import BulkUpload from 'components/modals/BulkUpload'
import useExcelUpload from 'hooks/useExcelUpload'
import { excelUploads } from 'utils/excelUpload'
import useAuthContext from 'contexts/Auth/useAuthContext'
import { ref, uploadBytes } from 'firebase/storage'
import firebase from 'config/firebase'
import { useNavigate } from 'react-router'
import Loader from 'components/loaders/Loader'
import { Row } from '@tanstack/react-table'

const ExpensesPage: FC = () => {
  const user = useAuthContext()
  const { expenses, loadNext, loading, loadingNextPage, setExpenses, breakdowns, availableCategories, setAvailableCategories } =
    useExpenses()
  const select = useSelect()
  const [exporting, setExporting] = useState(false)
  const filter = useFilteringContext()
  const navigate = useNavigate()

  const excelUpload = useExcelUpload(excelUploads.ExpenseExcelUpload)
  const modalConfig = { template: excelUpload, respondWithFile: true, additionalInfo: "DATE format should be 'YYYY-MM-DD'", withColumnMapping: true }
  const [uploadBulk, BulkUploadModal] = useAwaitableModal(BulkUpload, modalConfig)
  const [importing, setImporting] = useState<'uploading' | 'processing' | false>(false)

  const importExcel = useCallback(async () => {
    if (importing) return
    setImporting('uploading')
    return uploadBulk()
      .then(async (res) => {
        if (!res?.result) return
        const { result } = res
        const dest = `users/${user?.uid}/expenses/${Date.now()}.xlsx`
        await uploadBytes(ref(firebase.storage, dest), result as File).then(async () => {
          setImporting('processing')
          return expensesApi.uploadExpenses(dest).then(() => {
            navigate(0)
          })
        })
      })
      .finally(() => setImporting(false))
  }, [importing, uploadBulk, user?.uid])

  const [onDelete, deleting, AreYouSureDeleteModal] = useBulkDelete(select, expensesApi.deleteExpenses, {
    header: 'Delete Expenses',
    description: 'Are you sure you want to delete these Expenses?',
  })

  const [onStop, stopping, AreYouSureStopModal] = useBulkDelete(select, expensesApi.stopExpenses, {
    header: 'Stop Expenses',
    description: 'Are you sure you want to stop these Expenses? This will also delete the subsequent expenses.',
  })

  const createExpense = useCallback(
    async (expense: Omit<IExpense, 'id' | 'createAt'>) => {
      const created = await expensesApi.createExpense(expense)
      setAvailableCategories(created.categories)
      return created.expenses
    },
    [setAvailableCategories]
  )

  const onCreated = useCallback(
    (expenses: IExpense[], categories?: string[]) => {
      setExpenses((old) => old && [...expenses, ...old.filter((e) => !expenses.find((n) => n.id === e.id))])
      categories && setAvailableCategories(categories)
    },
    [setExpenses, setAvailableCategories]
  )

  const formConfig = useMemo(
    () => ({
      onSubmit: createExpense as any,
      config: {
        title: 'New Expense',
        sections: [
          {
            title: 'Expense Details',
            fields: [
              { id: 'title', name: 'Title', type: 'text' as const, required: true },
              { id: 'amount', name: 'Amount', type: 'number' as const, required: true },
              {
                id: 'category',
                name: 'Category',
                type: 'select' as const,
                extendable: true,
                options: availableCategories || [],
                required: true,
              },
              { id: 'frequency', name: 'Frequency', type: 'select' as const, options: ['One Time', 'Weekly', 'Monthly', 'Quarterly', 'Yearly'], required: true },
              { id: 'dateOfExpense', name: 'Date Of Expense', type: 'date' as const, required: true },
            ],
          },
        ],
      },
    }),
    [createExpense, availableCategories]
  )

  const [addExpense, AddSupplierComponent] = useAwaitableModal(CreateFormModal, formConfig)

  const onAdd = useCallback(() => {
    addExpense(formConfig).then((expenses: IExpense[]) => {
      if (expenses) {
        onCreated(expenses)
      }
    })
  }, [addExpense, formConfig, onCreated])

  const onExport = useCallback(() => {
    if (exporting) return
    setExporting(true)
    expensesApi.exportExpenses(filter).then(() => setExporting(false))
  }, [filter, exporting])

  type ExpenseRow = { type: 'data'; expense?: IExpense } | { type: 'divider'; month: string }

  const rows = useMemo(() => {
    if (!expenses) return undefined
    const rows: ExpenseRow[] = []
    let lastMonth = ''
    let rowsThisMonth: ExpenseRow[] = []
    let thisMonthUsedByCategory: Record<string, number> = {}

    function pushRowsThisMonth() {
      rows.push(...rowsThisMonth)
    }

    for (const expense of expenses) {
      const date = new Date(expense.dateOfExpense)
      const month = date.toLocaleString('en-US', { month: 'long', year: 'numeric' })
      if (month !== lastMonth) {
        pushRowsThisMonth()
        rowsThisMonth = []
        thisMonthUsedByCategory = availableCategories.reduce((acc, cat) => ({ ...acc, [cat]: 0 }), {})
        rows.push({
          type: 'divider',
          month,
        })
        lastMonth = month
      }
      thisMonthUsedByCategory[expense.category] += expense.amount
      rowsThisMonth.push({ type: 'data', expense })
    }
    pushRowsThisMonth()
    return rows
  }, [expenses, availableCategories])

  const deferredRows = useDeferredValue(rows)

  const renderRow = useCallback(
    (row: Row<ExpenseRow>) => (row.original.type === 'data' ? row.original.expense ? <Expense key={row.original.expense.id} row={row as any} onUpdate={onCreated} /> : null : <MonthHeader key={row.original.month} month={row.original.month} />),
    [onCreated]
  )

  const bulkInProgress = deleting || stopping || exporting || !!importing
  const canDoBulk = select.selected.length > 0 || select.allSelected

  return (
    <ContentLayout
      wrapperClass="!overflow-y-auto !bg-surface-light !border-none !flex !flex-col"
      underSearch={
        canDoBulk ? (
          <div className="flex gap-4 items-center">
            <button className="button-destructive w-max" onClick={onDelete} disabled={bulkInProgress}>
              Delete
            </button>
            <button className="button-destructive w-max" onClick={onStop} disabled={bulkInProgress}>
              Stop Expenses
            </button>
          </div>
        ) : undefined
      }
      buttons={
        <div className="flex items-center gap-4">
          <PeriodFilter />
          <button className="button-secondary" onClick={onAdd}>
            <Icon name="Plus" className="w-4 h-4" />
            Add Expense
          </button>
          <button className="button-secondary" onClick={onExport}>
            <Icon name="DownloadSimple" className="w-4 h-4 scale-1" />
            Export CSV
          </button>
          {!importing ? (
            <button className="button-primary" onClick={importExcel}>
              <Icon name="UploadSimple" className="w-4 h-4 scale-1" />
              Import CSV
            </button>
          ) : (
            <Loader size={24} />
          )}
        </div>
      }
    >
      {<AddSupplierComponent />}
      {<AreYouSureDeleteModal />}
      {<AreYouSureStopModal />}
      {<BulkUploadModal />}
      <div className="flex flex-col w-full h-full gap-4 [&>div:nth-child(2)]:border [&>div:nth-child(2)]:border-border-primary [&>div:nth-child(2)]:rounded-lg [&>div:nth-child(2)]:min-h-full">
        <Graphs breakdowns={breakdowns} availableCategories={availableCategories} />
        <Table
          name="expenses"
          columns={columnDef}
          initialPinState={{
            left: ["dateOfExpense", "title"]
          }}
          items={deferredRows}
          renderRow={renderRow}
          loading={loading || !expenses}
          loadingNext={!loading && loadingNextPage}
          onBottom={loadNext}
          select={select}
          extra={{
            availableCategories,
          }}
        />
      </div>
    </ContentLayout>
  )
}

export default withFiltering(ExpensesPage, "expenses")
