import { FC, PropsWithChildren, useEffect, useState } from "react";
import titleContext from "./titleContext";
import { isDemo } from "constants/demo";

const TitleProvider: FC<PropsWithChildren> = ({children}) => {
    const [text, setText] = useState<string>()

    useEffect(() => {
        const textToSet = text?.replace("__back__", "").replace("__back2__", "")
        document.title = [isDemo ? "[DEMO]" : "", textToSet, "Third Party Profits"].filter(Boolean).join(" | ")
    }, [text])

    return (
        <titleContext.Provider value={{text, setText}}>
            {children}
        </titleContext.Provider>
    )
}

export default TitleProvider