import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import { userData } from "Root"
import housedAsinsApi from "api/housedAsins"
import BulkUpload from "components/modals/BulkUpload"
import firebase from "config/firebase"
import useFilteringContext from "contexts/Filter/useFilteringContext"
import useUserContext from "contexts/User/useUserContext"
import { uploadBytes, ref } from "firebase/storage"
import useAwaitableModal from "hooks/useAwaitableModal"
import useExcelUpload from "hooks/useExcelUpload"
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { MasterUPCCatalogItem } from "types/housedAsins"
import { IApiFilter } from "types/tableFiltering"
import { MasterCatalogExcelUpload } from "utils/excelUpload/templates"
import useSupplierNames from "./useSupplierNames"

const queryFn = async ({pageParam: page, queryKey}: {pageParam: number, queryKey: [string, IApiFilter]}) => {
  return housedAsinsApi.getMasterCatalog({page, ...queryKey[1]})
}

const useMasterCatalog = () => {
  const filter = useFilteringContext()
  const {search, ordering, opFilters: filters} = filter
  const path = useLocation().pathname
  const {supplierNames} = useSupplierNames()

  const queryKey = useMemo(() => (
    [
    'master-catalog',
    {
      search,
      ordering,
      filters
    }
    ] as [string, IApiFilter]
  ), [search, ordering, filters])

  const saveScrollKey = useMemo(() => {
    const {search, filters} = queryKey[1]
    if (!search && Object.keys(filters || {}).length === 0) return undefined
    return `master-catalog-${search}-${JSON.stringify(filters)}-${JSON.stringify(queryKey[1].ordering)}`
  }, [queryKey])

  const {data, refetch, fetchNextPage, isFetchingNextPage, hasNextPage: hasMore} = useInfiniteQuery(
    {
      queryKey: queryKey,
      queryFn,
      initialPageParam: 1,
      getNextPageParam: (lastPage) => lastPage.next,
      enabled: path === '/app/housed/master-catalog',
      staleTime: (query) => {
        const {search, filters} = query.queryKey[1]
        if (search || Object.keys(filters || {}).length > 0) return Infinity
        return 0
      }
    }
  )
  const loadingNextPage = isFetchingNextPage

  const catalog = useMemo(() => data ? [...data.pages.flatMap((p) => p.items)].dedupe(i => i.id + i.asin) : undefined, [data])

  const {data: overview, isFetching: overviewFetching} = useQuery({
    queryKey: ['master-catalog-overview', queryKey[1]],
    queryFn: () => housedAsinsApi.getMasterCatalogOverview(queryKey[1])
  })

  const {data: counts} = useQuery({
    queryKey: ['master-catalog-counts'],
    queryFn: () => housedAsinsApi.getMasterCatalogSyncState()
  })

  const exportCSV = useCallback((columnMapping?: {value: string, label: string}[]) => {
    housedAsinsApi.exportMasterCatalog({...queryKey[1], columnMapping})
  }, [queryKey])

  const qc = useQueryClient()

  const setItems: Dispatch<SetStateAction<MasterUPCCatalogItem[] | undefined>> = (action) => {
    qc.setQueryData(queryKey, (old: typeof data) => {
      if (!old) return old
      if (action instanceof Function){
        return {
          ...old,
          pages: old.pages.map((p) => ({
            ...p,
            items: action(p.items)
          }))
        }
      } if (!action) {
        refetch()
      } else {
        return {
          ...old,
          pages: old.pages.map((p, i) => ({
            ...p,
            items: action[i]
          }))
        }
      }
    })
  };

  const entryExcelUpload = useExcelUpload(MasterCatalogExcelUpload)

  const [requestUpload, UploadModal] = useAwaitableModal(
    BulkUpload, {
      template: entryExcelUpload,
      respondWithFile: true,
      withColumnMapping: true,
      defaultValueChoices: {
        "Supplier": supplierNames || []
      }
    })
  const [importing, setImporting] = useState<boolean | 'uploading' | 'processing'>(false)
  const user = useUserContext()
  const navigate = useNavigate()

  const importExcel = useCallback(async () => {
    if (importing) return
    if (!supplierNames) return
    setImporting('uploading')
    return requestUpload({
      template: entryExcelUpload,
      respondWithFile: true,
      withColumnMapping: true,
      defaultValueChoices: {
        "Supplier": supplierNames || []
      }
    })
      .then(async (res) => {
        if (!res?.result) return
        const { result } = res
        const dest = `users/${user?.id}/masterCatalogs/${Date.now()}.xlsx`
        await uploadBytes(ref(firebase.storage, dest), result as File).then(async () => {
          setImporting('processing')
          return housedAsinsApi.uploadMasterCatalog(dest, (result as File).name, res.columnMappings).then(() => userData.current?.setUser((old) => old && { ...old, masterUPCCatalog: dest })).then(() => {
            navigate(0)
          })
        })
      })
      .finally(() => setImporting(false))
  }, [importing, requestUpload, user?.id, supplierNames])

  return {
    counts: counts?.counts,
    overview: overviewFetching ? undefined : overview,
    loadCatalog: refetch,
    saveScrollKey: saveScrollKey,
    loadCatalogNextPage: isFetchingNextPage ? undefined : fetchNextPage,
    exportCSV,
    loadingNextPage,
    hasMore,
    setCatalog: setItems,
    catalog,
    UploadModal,
    importExcel,
    importing,
    supplierNames,
  }
}

export default useMasterCatalog
