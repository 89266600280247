import { FC, useMemo } from 'react'
import DashboardTile from './DashboardTile'
import { usNumber } from 'utils/formatting'

interface props {
  overviews?: Record<string, string>[]
}

const Dashboard: FC<props> = ({ overviews }) => {
  const overview = useMemo(() => {
    if (!overviews) return null
    const totalGross = overviews.reduce((acc, cur) => acc + Number(cur.totalGross), 0)
    const totalCogs = overviews.reduce((acc, cur) => acc + Number(cur.totalCogs), 0)
    const revenue = overviews.reduce((acc, cur) => acc + Number(cur.revenue), 0)
    const sellable = overviews.reduce((acc, cur) => acc + Number(cur.sellable), 0)
    const landedCost = overviews.reduce((acc, cur) => acc + Number(cur.avgLandedCost) * Number(cur.sellable), 0)
    const avgMargin = (totalGross * 100) / revenue
    const avgRoi = (totalGross * 100) / totalCogs
    const avgLandedCost = landedCost / sellable
    const avgGross = totalGross / sellable

    return {
      totalGross,
      totalCogs,
      revenue,
      sellable,
      avgMargin,
      avgRoi,
      avgLandedCost,
      avgGross,
    }
  }, [overviews])

  const dataPointsBySupplier = useMemo(() => {
    if (!overviews) return null
    const totalGross = overviews.map((o) => ({ supplier: o.supplier, value: Number(o.totalGross) }))
    const totalCogs = overviews.map((o) => ({ supplier: o.supplier, value: Number(o.totalCogs) }))
    const revenue = overviews.map((o) => ({ supplier: o.supplier, value: Number(o.revenue) }))
    const sellable = overviews.map((o) => ({ supplier: o.supplier, value: Number(o.sellable) }))
    const avgMargin = overviews.map((o) => ({ supplier: o.supplier, value: Number(o.avgMargin) }))
    const avgRoi = overviews.map((o) => ({ supplier: o.supplier, value: Number(o.avgRoi) }))
    const avgLandedCost = overviews.map((o) => ({ supplier: o.supplier, value: Number(o.avgLandedCost) }))
    const avgGross = overviews.map((o) => ({ supplier: o.supplier, value: Number(o.avgGross) }))

    return {
      totalGross,
      totalCogs,
      revenue,
      sellable,
      avgMargin,
      avgRoi,
      avgLandedCost,
      avgGross,
    }
  }, [overviews])

  const priceFormatter = (value?: number) => (value !== undefined ? `$${usNumber(value)}` : '...')
  const percentFormatter = (value?: number) => (value !== undefined ? `${usNumber(value)}%` : '...')
  const intFormatter = (value?: number) => (value !== undefined ? `${usNumber(value, 0)}` : '...')

  return (
    <div className="w-full overflow-x-auto overflow-y-hidden pb-4">
      <div className="w-full flex md:grid-cols-3 2xl:grid-cols-7 gap-4 px-4 py-4 grow overflow-x-auto min-w-min">
        <DashboardTile title="Profits" breakdowns={dataPointsBySupplier?.totalGross} className="bg-emerald-500" formatter={priceFormatter} value={overview?.totalGross} />
        <DashboardTile title="AVG Profit Per Unit" className="bg-emerald-400" breakdowns={dataPointsBySupplier?.avgGross} formatter={priceFormatter} value={overview?.avgGross} />
        <DashboardTile title="AVG Margin" className="bg-blue-500" breakdowns={dataPointsBySupplier?.avgMargin} formatter={percentFormatter} value={overview?.avgMargin} />
        <DashboardTile title="AVG ROI" className="bg-blue-400" breakdowns={dataPointsBySupplier?.avgRoi} formatter={percentFormatter} value={overview?.avgRoi} />
        <DashboardTile title="Sellable Units" className="bg-slate-500" breakdowns={dataPointsBySupplier?.sellable} formatter={intFormatter} value={overview?.sellable} />
        <DashboardTile title="COGS" className="bg-rose-500" breakdowns={dataPointsBySupplier?.totalCogs} formatter={priceFormatter} value={overview?.totalCogs} />
        <DashboardTile title="Revenue" className="bg-orange-500" breakdowns={dataPointsBySupplier?.revenue} formatter={priceFormatter} value={overview?.revenue} />
      </div>
    </div>
  )
}

export default Dashboard
