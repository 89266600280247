import { createRef, FC, useImperativeHandle, useState } from 'react'
import Modal from './Modal'

export const subscriptionRequiredBlockerRef = createRef<{ open: () => void }>()

const SubscriptionRequiredBlocker: FC = () => {
  const [open, setOpen] = useState(false)

  useImperativeHandle(subscriptionRequiredBlockerRef, () => ({
    open: () => setOpen(true),
  }))

  return (
    <Modal open={open} close={() => setOpen(false)}>
      <div className="bg-white p-4 rounded-lg w-96">
        <h2 className="text-2xl font-bold">This feature is not available with your plan</h2>
        <p className="mt-4">Please subscribe to <b>All Features</b> Plan.</p>
        <div className="flex items-center w-full justify-evenly mt-8 gap-4">
          <button className="button-secondary grow" onClick={() => setOpen(false)}>
            Close
          </button>
          <button className="button-primary grow" onClick={() => window.location.replace("https://app.thirdpartyprofits.com/app/settings/billing")}>
            Subscribe Now
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default SubscriptionRequiredBlocker
