import useTableContext from "contexts/Table/useTableContext";
import { FC, HTMLProps, PropsWithChildren, useEffect, useRef } from "react";

const TR: FC<PropsWithChildren<HTMLProps<HTMLTableRowElement>>> = ({ children, ...props }) => {
    const ref = useRef<HTMLTableRowElement>(null);
    const {table} = useTableContext();

    useEffect(() => {
        if (!ref.current) return;
        const rows = table.getRowCount();
        ref.current.style.setProperty("z-index", (rows - ref.current.rowIndex).toString())
    }, [table])

    return <tr ref={ref} {...props} >{children}</tr>
}

export default TR;