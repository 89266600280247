import useTableContext from 'contexts/Table/useTableContext'
import { FC } from 'react'
import { getCommonPinningStyles } from './pinningStyles'
import TR from './TR'

interface Props {
  rows?: number
}

const TableLoader: FC<Props> = ({rows = 20}) => {
  const {
    table: {getAllColumns},
  } = useTableContext()

  const rowData = Array.from({ length: rows })

  return (
    <>
      {rowData.map((_, i) => (
        <TR key={i}>
          {getAllColumns().map((col, j) => (
            <td key={`${i}_${j}`} style={{...getCommonPinningStyles(col)}}>
              <div className="animate-pulse h-6 bg-surface-secondary rounded-md" />
            </td>
          ))}
        </TR>
      ))}
    </>
  )
}

export default TableLoader
