import React, {FC} from 'react'
import Icon from "../../../../assets/icons/iconset";
import Product from './components/Product'
import {useParams} from "react-router";
import useTitle from "../../../../contexts/Title/useTitle";
import { useQuery } from '@tanstack/react-query';
import warehouseApi from 'api/warehouse';




const InboundReceiving: FC = () => {
    const {
        id,
        productId
    } = useParams<{ id: string, productId: string }>()
    const {data} = useQuery({
        queryKey: ['warehouse-inbound-shipment', id, "details", productId],
        queryFn: async ({queryKey}) => {
            const [, id, , productId] = queryKey as [string, string, string, string]
            return warehouseApi.getWarehouseInboundShipmentItem(id, productId)
        }
    })

    useTitle('__back2__Inbound Receiving')
    return (
        <div className='grid w-full h-full overflow-hidden'>
            <div className='flex justify-end my-4 mr-6 space-x-4'>
                <button className="button-secondary">
                    <Icon name="Plus" className="w-4 h-4"/>
                    Request Credit
                </button>
                <button className="button-secondary">
                    <Icon name="Plus" className="w-4 h-4"/>
                    Add Back to PO
                </button>
                <button className="button-secondary">
                    <Icon name="Plus" className="w-4 h-4"/>
                    Add to Pallet
                </button>
                <button className="button-primary">
                    <Icon name="Check" className="w-4 h-4"/>
                    Complete
                </button>
            </div>
            {!data ? <div>Loading...</div> : <Product product={data}/>}
        </div>
    )

}

export default InboundReceiving
