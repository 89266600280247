import { useQuery } from "@tanstack/react-query";
import api from "api";
import useUserContext from "contexts/User/useUserContext";
import { FC, useEffect } from "react";

const CheckoutSubscriptionPage: FC = () => {
    const user = useUserContext();
    const selectedSubscription = Object.entries(user?.subscriptionSelection || {}).filter(([, period]) => period)[0];

    const {data: link, isSuccess} = useQuery({
        queryKey: ['checkout', selectedSubscription],
        queryFn: () => api.selectPlan(selectedSubscription[0], selectedSubscription[1]),
    });

    useEffect(() => {
        if (isSuccess && link) {
            window.open(link, '_self');
        }
    }, [isSuccess, link]);

    return (
        <div>
            Loading...
        </div>
    )
}

export default CheckoutSubscriptionPage;