import { FC } from 'react'
import { ISidebarButton } from './types'
import { LogoSrc } from 'assets'
import useLocalConfig from 'hooks/useLocalConfig'
import Icon from 'assets/icons/iconset'
import SidebarButton from './components/Routes'

interface props {
  buttons?: ISidebarButton[]
}

const Sidebar: FC<props> = ({ buttons = [] }) => {
  const {config, setLocalConfig} = useLocalConfig()
  const sidebarCollapsed = !!config.sidebarCollapsed

  const toggleSidebar = () => setLocalConfig((prev) => ({...prev, sidebarCollapsed: !sidebarCollapsed}))
  
  const wrapperCollapsed = "pt-5 w-[4.5rem]"
  const wrapperExpanded = "pt-8 w-[18rem]"

  return (
    <div 
    className={[
      "flex flex-col border-r border-r-border-secondary gap-[2.75rem] z-[100] relative pb-5 items-center bg-surface-primary overflow-hidden shrink-0 transition-all",
      sidebarCollapsed ? wrapperCollapsed : wrapperExpanded
    ].asClass
    }
    >
      <img src={LogoSrc} alt="logo" className="w-[4rem] object-cover" />
      <div 
      className="flex flex-col items-start w-full px-4 gap-4 grow overflow-y-scroll"
      style={{scrollbarWidth: sidebarCollapsed ? "none" : "unset"}}
      >
        {buttons.map((button) => (
          <SidebarButton
            key={button.name}
            route={button}
            sidebarCollapsed={sidebarCollapsed}
            />
        ))}
      </div>
      <div onClick={toggleSidebar} className={["flex w-full px-4", sidebarCollapsed ? "justify-center" : "justify-end"].asClass}>
        <button className="button-icon-tertiary !p-1 cursor-pointer">
          <Icon name="CaretDoubleLeft" className={sidebarCollapsed ? "rotate-180" : ""}/>
        </button>
      </div>
    </div>
  )
}

export default Sidebar
