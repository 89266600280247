import api from 'api'
import { subscriptionPlans } from 'constants/subscriptions'
import useUserContext from 'contexts/User/useUserContext'
import { FC, useCallback, useEffect, useState } from 'react'

const SubscriptionSettings: FC = () => {
  const user = useUserContext()
  const [submitting, setSubmitting] = useState(false)
  const [period, setPeriod] = useState<'monthly' | 'yearly'>('monthly')

  const [selectedPlan, selectedPeriod] = Object.entries(user?.subscriptions || {}).filter(([, period]) => period)[0]

  const cancel = useCallback(async () => {
    if (submitting) return
    setSubmitting(true)
    await api
      .cancelSubscription()
      .then(() => window.location.reload())
      .finally(() => setSubmitting(false))
  }, [submitting])

  const submit = useCallback(
    async (plan: string, period: 'monthly' | 'yearly') => {
      if (selectedPlan && selectedPeriod) {
        if (plan === selectedPlan && period === selectedPeriod) {
          return cancel()
        }
      }
      if (submitting) return
      setSubmitting(true)
      await api
        .selectPlan(plan, period)
        .then((url) => {
          if (url) {
            window.open(url, '_self')
          }
        })
        .finally(() => setSubmitting(false))
    },
    [submitting]
  )

  const openBillingPortal = useCallback(async () => {
    if (submitting) return
    setSubmitting(true)
    await api
      .openBillingPortal()
      .then((url) => {
        if (url) {
          window.open(url, '_self')
        }
      })
      .finally(() => setSubmitting(false))
  }, [submitting])

  useEffect(() => {
    if (selectedPeriod) {
      setPeriod(selectedPeriod as 'monthly' | 'yearly')
    }
  }, [selectedPeriod])

  return (
    <>
      <div className="p-4 border-b border-b-slate-200">
        <span className="text-base-900 font-medium text-base">Billing</span>
      </div>
      <div className="flex-cool overflow-y-auto items-center grow">
        <div className="grid grid-cols-2 gap-4 max-w-64 p-4 grow">
          <h2 className="col-span-2 text-center font-semibold">Available plans</h2>
          <button onClick={() => setPeriod('monthly')} className={period === 'monthly' ? "button-primary h-min" : "button-secondary h-min"}>
            Monthly
          </button>
          <button onClick={() => setPeriod('yearly')} className={period === 'yearly' ? "button-primary h-min" : "button-secondary h-min"}>
            Yearly
          </button>
          {subscriptionPlans.map((plan) => (
            <div key={plan.name} className="bg-white p-4 border border-gray-200 flex flex-col gap-1 grow">
              <h3>{plan.name}</h3>
              <p className="text-xl grow">
                {period === 'monthly' ? `$${plan.price.monthly}` : `$${plan.price.yearly}`} {period === 'yearly' ? ' / year' : ' / month'}
              </p>
              <button className={
                plan.id === selectedPlan && period === selectedPeriod  ? "button-secondary" : "button-primary"
              } onClick={() => submit(plan.id, period)} disabled={submitting}>
                {plan.id === selectedPlan && period === selectedPeriod ? 'Cancel' : 'Select Plan'}
              </button>
            </div>
          ))}
        </div>
        <div className="bg-slate-900 flex items-center justify-center w-full py-10">
          <button className="button-primary" onClick={openBillingPortal} disabled={submitting}>
            Billing Portal
          </button>
        </div>
      </div>
    </>
  )
}

export default SubscriptionSettings
