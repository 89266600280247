import api from "api";
import { subscriptionPlans } from "constants/subscriptions";
import { FC, useCallback, useState } from "react";

const PlansSelectPage: FC = () => {
    const [period, setPeriod] = useState<'monthly' | 'yearly'>('monthly');
    const [submitting, setSubmitting] = useState(false);

    const submit = useCallback(async (plan: string, period: 'monthly' | 'yearly') => {
        if (submitting) return;
        setSubmitting(true);
        await api.selectPlan(plan, period)
            .then((url) => {
                if (url) {
                    window.open(url, '_self');
                }
            })
            .finally(() => setSubmitting(false));
        }, [submitting]);

    return (
        <div className="w-full h-full flex items-center justify-center p-8">
        <div className="grid grid-cols-2 gap-4 max-w-64">
            <h2 className="col-span-2 text-center font-semibold">Select a Plan</h2>
            <button onClick={() => setPeriod('monthly')} className={period === 'monthly' ? 'button-primary' : 'bg-white text-blue-500'}>
                Monthly
            </button>
            <button onClick={() => setPeriod('yearly')} className={period === 'yearly' ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'}>
                Yearly
            </button>
            {subscriptionPlans.map((plan) => (
                <div key={plan.name} className="bg-white p-4 border border-gray-200 flex flex-col gap-1">
                    <h3>{plan.name}</h3>
                    <p>{period === 'monthly' ? `$${plan.price.monthly}` : `$${plan.price.yearly}`}</p>
                    <button className="button-primary" onClick={() => submit(plan.id, period)} disabled={submitting}>
                        Select
                    </button>
                </div>
            ))}
        </div>
        </div>
    )
}

export default PlansSelectPage;
