import { useQuery } from "@tanstack/react-query"
import suppliersApi from "api/suppliers"

const queryFn = async () => {
    return suppliersApi.getWebsites()
}

const useSupplierWebsites = () => {
    const {data: websites} = useQuery({
        queryKey: ['supplierWebsites'],
        queryFn,
    })

    return {
        websites
    }
}

export default useSupplierWebsites