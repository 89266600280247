import { FC } from 'react'
import { IWarehouseInboundShipmentItemWithProductData } from 'types/warehouse'
import Icon from '../../../../../assets/icons/iconset'
import Barcode from './Barcode'
import Dropdown from '../../../../../components/Dropdown'
import { usNumber } from 'utils/formatting'
import FullAmazonProductImage from 'components/AmazonProductImage/full'

interface props {
  product: IWarehouseInboundShipmentItemWithProductData
}

const Product: FC<props> = ({ product }) => {
  const unitsExpected = product.units
  const unitsReceived = product.arrivedUnits
  const unitsDiscrepancy = unitsReceived !== null ? unitsExpected - unitsReceived : null
  const sellableReceived = product.arrivedUnits !== null ? product.arrivedUnits / product.bundleUnits : null

  return (
    <div className="flex flex-col lg:flex-row h-full w-full overflow-hidden gap-4 p-4">
      <div className="w-full lg:w-1/3 h-full bg-white border-gray-200 rounded-lg p-4 ">
        <FullAmazonProductImage asin={product.asin} className="w-full h-full object-contain" />
      </div>
      <div className="flex flex-col w-full lg:w-2/3 h-full bg-white border-gray-200 rounded-lg p-4 overflow-y-auto">
        <div className="flex flex-col">
          <div>
            <h1>{product.productData.title}</h1>
          </div>
          <div className="flex flex-initial w-full h-16 space-x-4">
            <div className="flex-row content-center bg-white">
              <p className="text-base text-gray-500">ASIN</p>
              <p className="text-base text-black">{product.asin}</p>
            </div>
            <div className="flex-row content-center bg-white ">
              <p className="text-base text-gray-500">Case Pack</p>
              <p className="text-base text-black">{product.casePack}</p>
            </div>
            <div className="flex-row content-center bg-white ">
              <p className="text-base text-gray-500">Bundle Qty</p>
              <p className="text-base text-black">{product.bundleUnits}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full h-max bg-white rounded-lg border-gray-200 border-[1px] border-opacity-75">
          <div className="grid grid-cols-4 w-full border-gray-200 border-b-[1px] p-3">
            <div className="flex w-full align-bottom space-x-2">
              <p className="text-base font-medium">Units Ordered</p>
              <p className="text-base">{usNumber(product.units, 0)}</p>
            </div>
            <div className="flex w-full space-x-2 overflow-hidden">
              <p className="text-base font-medium">Invoiced Unit Qty</p>
              <p className="text-base">{usNumber(product.units, 0)}</p>
            </div>
            <div className="flex w-full space-x-2">
              <p className="text-base font-medium">Received Unit Qty</p>
              <p className="text-base">{usNumber(product.arrivedUnits, 0)}</p>
            </div>
            <div className="flex w-full space-x-2">
              <p className="text-base font-medium">Discrepancy Unit Qty</p>
              <p className="text-base text-red-500">{product.arrivedUnits !== null ? usNumber(product.units - product.arrivedUnits, 0) : '---'}</p>
            </div>
          </div>
          <div className="flex w-full border-b-[1px] p-3">
            <div className="flex w-full space-x-2">
              <p className="text-base font-medium">Cases Ordered</p>
              <p className="text-base">{usNumber(product.caseQty, 0)}</p>
            </div>
            <div className="flex w-full space-x-2">
              <p className="text-base font-medium">Invoiced Case Qty</p>
              <p className="text-base">{usNumber(product.caseQty, 0)}</p>
            </div>
            <div className="flex w-full space-x-2">
              <p className="text-base font-medium">Received Case Qty</p>
              <p className="text-base">{usNumber(product.arrivedCaseQty, 0)}</p>
            </div>
            <div className="flex w-full space-x-2">
              <p className="text-base font-medium">Discrepancy Case Qty</p>
              <p className="text-base text-red-500">{product.arrivedCaseQty !== null ? usNumber(product.caseQty - product.arrivedCaseQty, 0) : '---'}</p>
            </div>
          </div>
          <div className="flex w-1/2 border-b-[1px] space-x-4 p-3 col-span-4">
            <div className="flex w-full space-x-2">
              <p className="w-1/2 text-base font-medium">Sellable ASINs Received</p>
              <p className="text-base">{usNumber(sellableReceived, 0)}</p>
            </div>
          </div>
          <div className="flex w-1/2 border-b-[1px] space-x-4 p-3">
            <div className="flex w-full space-x-2">
              <p className="w-1/2 text-base font-medium">Unit Cost</p>
              <p className="text-base">${product.unitCost}</p>
            </div>
          </div>

          <div className="flex w-1/2  border-b-[1px] space-x-4 p-3">
            <div className="flex w-full space-x-2">
              <p className="w-1/2 text-base font-medium">Discrepancy Amount</p>
              <p className="text-red-500 text-base">${usNumber(product.unitCost * (unitsDiscrepancy || 0), 2)}</p>
            </div>
          </div>
          <div className="flex w-1/2  border-b-[1px] space-x-4 p-3">
            <div className="flex w-full space-x-2">
              <p className="w-1/2 text-base font-medium">Prep Instructions</p>
              <p className="text-base">{product.housedAsin.prepCost.name}</p>
            </div>
          </div>
          <div className=" w-1/2  border-b-[1px] space-x-4 p-3">
            <div className="flex w-full space-x-2">
              <p className="w-1/2 text-base font-medium">Notes</p>
              <p className="text-base">---</p>
            </div>
          </div>
          <div className="flex w-1/2 h-12 border-b-[1px] space-x-4 p-3">
            <div className="flex w-full space-x-2">
              <p className="w-1/2 text-base font-medium">Prep Type</p>
              <div className="input-box w-1/2 relative self-center">
                <input type="text" placeholder={'Search'} className="!px-8" />
                <div className="flex absolute w-full items-center justify-between h-10 px-2 pointer-events-none">
                  <Icon name="MagnifyingGlass" className="w-5 h-5" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-1/2 h-12 border-b-[1px] space-x-4 p-3">
            <div className="flex w-full space-x-2">
              <p className="w-1/2 text-base font-medium">Expiration Date</p>
              <div className="input-box w-1/2 relative self-center">
                <input type="text" placeholder={'Search'} className="!px-8" />
                <div className="flex absolute w-full items-center justify-between h-10 px-2 pointer-events-none">
                  <Icon name="MagnifyingGlass" className="w-5 h-5" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex w-1/2  border-b-[1px] space-x-4 p-3">
            <div className="flex w-full space-x-2">
              <p className="w-1/2 text-base font-medium">UPC Code</p>
              <p className="text-base">{product.housedAsin.upc || product.productData.upc}</p>
            </div>
          </div>
          <div className="flex w-1/2  border-b-[1px] space-x-4 p-3">
            <div className="flex w-full space-x-2">
              <p className="w-1/2 pt-2 text-base font-medium">UPC Batchcode</p>
              <p>
                <Barcode upcCode={product.housedAsin.upc || product.productData.upc || ''} />
              </p>
            </div>
          </div>
          <div className="flex w-1/2  border-b-[1px] space-x-4 p-3">
            <div className="flex w-full space-x-2">
              <p className="w-1/2 text-base font-medium">Vendor SKU</p>
              <p className="text-base">{product.housedAsin.vendorSKU}</p>
            </div>
          </div>
          <div className="flex w-1/2 border-b-[1px] space-x-4 p-3">
            <div className="flex w-full space-x-2">
              <p className="w-1/2 text-base font-medium">MSKU</p>
              <p className="text-base">{product.housedAsin.sku}</p>
            </div>
          </div>
          <div className="flex w-1/2 border-b-[1px] space-x-4 p-3">
            <div className="flex w-full space-x-2">
              <p className="w-1/2 text-base font-medium">Discrepancy Type</p>
              <Dropdown
                anchorEl={
                  <div className="flex space-x-1">
                    <p className="text-gray-500">Select a credit reason</p>
                    <Icon name="CaretDown" className="w-4 h-5" />
                  </div>
                }
              >
                <div className="flex flex-col bg-white rounded-lg">
                  <button className="button-tertiary !text-left !justify-start !text-text-secondary">View</button>
                  <div className="w-full h-px bg-border-secondary" />
                  <button className="button-tertiary !text-left !justify-start !text-text-secondary">Download</button>
                  <div className="w-full h-px bg-border-secondary" />
                  <button className="button-tertiary !text-left !justify-start !text-text-secondary">Delete</button>
                </div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Product
