import axios from './axios'
import { AxiosInstance } from 'axios'
import { BasePrice, BasePricePreference } from 'types/user'

class Api {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  signup = async (data: { email: string; password: string; name: string, phone: string}) => {
    const sp = new URLSearchParams(window.location.search)
    const plan = sp.get('plan')
    const period = sp.get('period')
    const subscription = { plan, period }
    return this.axios.post('/auth/signup', {...data, subscription}).then((res) => res.data as { token: string; user: { id: string } })
  }

  getReferralEmail = async (code: string) => {
    return this.axios.get('/invited/' + code).then((res) => res.data)
  }

  getUser = async (userId: string = 'me') => {
    if (userId === 'me' && Rewardful.referral) {
      return this.axios.get('/user/me?rewardfulReferralId=' + Rewardful.referral).then((res) => res.data)
    }
    return this.axios.get('/user/' + userId).then((res) => res.data)
  }

  getAmazonAuthUri = () => {
    return this.axios.get('/user/amazon/auth-uri').then((res) => res.data)
  }

  authorizeAmazon = (code: string, sellerId: string, state: string) => {
    return this.axios.put('/user/amazon/code', { code, sellerId, state }).then((res) => res.data)
  }

  getAmazonProductImage = async (asin: string) => {
    return this.axios.get<string | null>('/amazon/products/pictures/'+asin, {params: {nonce: 1}}).then(async res => {
      const link = res.data
      if (!link) return null
      return { asin, url: link }
    })
  }

  subscribeToNewsletter = async (data: {email: string, fullName: string, monthlyRevenue: string, businessModel: string, message: string}) => {
    return this.axios.post('/emailSubscribers/subscribe', data)
  }

  selectPlan = async (plan: string, period: string) => {
    return this.axios.post(`/subscriptions/${plan}/${period}`).then((res) => res.data.url as string | undefined)
  }

  cancelSubscription = async () => {
    return this.axios.delete('/subscriptions')
  }

  openBillingPortal = async () => {
    return this.axios.get('/subscriptions/billing-portal').then((res) => res.data.url as string)
  }

  getBasePricePreferences = async () => {
    return this.axios.get('/user/base-price-preferences').then((res) => res.data as BasePricePreference)
  }

  updateBasePricePreferences = async <T extends keyof BasePricePreference>(key: T, value: BasePricePreference[T]) => {
    return this.axios.put('/user/base-price-preferences', {key, value}).then((res) => res.data as BasePricePreference)
  }
}

const api = new Api()

export default api
