import Table from 'components/tables/Table'
import useSelect from 'hooks/useSelect'
import { FC, useCallback, useDeferredValue, useEffect, useMemo, useState } from 'react'
import columnDef from './tableDef'
import { HousedAsin } from 'types/housedAsins'
import Item from './Item'
import withFiltering from 'contexts/Filter/wrapper'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import Loader from 'components/loaders/Loader'
import Icon from 'assets/icons/iconset'
import { CloseIcon } from 'assets/icons'
import useSupplierNames from 'hooks/useSupplierNames'
import { Row } from '@tanstack/react-table'
import { frontendFilter } from 'hooks/frontendFilter'

interface props {
  open: boolean
  close: () => void
  items: HousedAsin[]
  createWorkingPO: (items: HousedAsin[]) => void
}

const AddAsin: FC<props> = ({ open, close, items: unfilteredItemsBase, createWorkingPO }) => {
  const select = useSelect()
  const { search, opFilters, ordering, setSearch, searching } = useFilteringContext()
  const [submitting] = useState(false)
  const { supplierNames } = useSupplierNames()

  const unfilteredItems = useMemo(() => {
    return unfilteredItemsBase.map((item) => {
      const supplyOptions = unfilteredItemsBase.filter((i) => i.asin === item.asin).length
      return {
        ...item,
        supplyOptions,
      }
    })
  }, [unfilteredItemsBase])

  const items = useDeferredValue(
    frontendFilter(unfilteredItems, {
      filters: opFilters,
      search,
      ordering,
    })
    .slice(0)
    .sort((a, b) => a.asin.localeCompare(b.asin))
    .sort((a, b) => b.supplyOptions - a.supplyOptions)
  )

  useEffect(() => {
    if (open) {
      unfilteredItems.forEach((item) => {
        const supplyOptions = item.supplyOptions
        if (supplyOptions < 2) {
          if (select.selected.includes(item.id)) {
            return
          }
          select.onSelectClick(item.id)
        } else {
          if (!select.selected.includes(item.id)) {
            return
          }
          select.onSelectClick(item.id)
        }
      })
    }
  }, [open, unfilteredItems])

  const renderRow = useCallback((row: Row<HousedAsin>) => <Item row={row} />, [])

  const canDoBulk = select.selected.length > 0 || select.allSelected

  const onCreateWorkingPO = useCallback(() => {
    const selectedItems = items.filter((item) => select.selected.includes(item.id))
    createWorkingPO(selectedItems)
  }, [createWorkingPO, items, select.selected])

  return (
    <div
      className="w-full h-full fixed inset-0 flex justify-end z-[100000]"
      style={{
        transform: `translateX(${open ? 0 : '100%'})`,
        transition: 'transform 0.5s',
        pointerEvents: open ? 'auto' : 'none',
      }}
      onClick={() => close()}
    >
      <div onClick={(e) => e.stopPropagation()} className="w-full max-w-[min(95%,40rem)] relative h-full bg-white drop-shadow-xl overflow-hidden">
        <CloseIcon className="absolute top-4 right-4 cursor-pointer z-10" onClick={() => close()} />
        <div className="flex flex-col min-h-full gap-6 p-4 h-full">
          <div className="flex flex-col gap-1">
            <span className="text-text-secondary text-sm mt-2">Please enter new ASINS in Housed ASINS.</span>
            <span className="text-text-secondary text-sm">Note: Profit is calculated based on current Amazon price.</span>
          </div>
          <div className="flex items-center gap-4">
            <div className="input-box relative w-full">
              <input type="text" value={search} onChange={(e) => setSearch(e.currentTarget.value)} placeholder="Search by UPC / ASIN..." className="!px-8 [&:not(:hover)]:!border-border-secondary" />
              <div className="flex absolute w-full items-center justify-between h-10 px-2 pointer-events-none">
                <Icon name="MagnifyingGlass" className="w-5 h-5" />
                {searching && <Loader size={20} />}
              </div>
            </div>
          </div>
          <div className="border border-border-primary bg-surface-primary rounded-lg h-full overflow-hidden">
            <Table name="master-catalog-add-working" columns={columnDef} initialPinState={{left: ["image", "asin"]}} renderRow={renderRow} select={select} noSelectAll items={items} extra={{supplierNames}} />
          </div>
          <div className="flex justify-end w-full">
            <button
              className="button-primary"
              disabled={submitting || !canDoBulk}
              onClick={() => {
                close()
                onCreateWorkingPO()
              }}
            >
              Add to Working POs
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withFiltering(AddAsin, "master-catalog-add-working")
