import { AxiosInstance } from 'axios'
import axios from './axios'
import {
  AsinEntryItem,
  AsinEntryItemInput,
  CreateHousedAsinInput,
  CreateMasterUPCCatalogItemInput,
  CreateWorkingPOInput,
  HousedAsin,
  HousedAsinInput,
  IHousedPreferences,
  IShippingCost,
  MasterUPCCatalogItem,
  MasterUPCSyncCounts,
  MSKUGeneration,
} from 'types/housedAsins'
import { IApiFilter } from 'types/tableFiltering'
import { handleError } from 'helpers/errors'
import { getIdToken } from 'utils/getIdToken'

class HousedAsinsApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  getMasterCatalog = async ({ page = 1, pageSize = 50, search, filters, ordering }: { page?: number; pageSize?: number } & IApiFilter) => {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    sp.append('pageSize', pageSize.toString())
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get(`/housed/master-catalog?${sp.toString()}`).then((res) => ({ ...res.data, search } as { items: MasterUPCCatalogItem[]; search?: string; next: number | null }))
  }

  getMasterCatalogSyncState = async () => {
    return this.axios.get(`/housed/master-catalog/counts`).then((res) => res.data as { counts: MasterUPCSyncCounts })
  }

  refreshMasterCatalog = async (reload = true) => {
    const base = 'https://us-central1-third-party-profits.cloudfunctions.net/api'
    return this.axios.post(base + '/housed/master-catalog/refresh').then(() => reload && window.location.reload())
  }

  getMasterCatalogOverview = async (filter: IApiFilter) => {
    const sp = new URLSearchParams()
    if (filter.search) sp.append('search', filter.search)
    if (filter.ordering) sp.append('orderBy', `${filter.ordering.by}:${filter.ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filter.filters && Object.keys(filter.filters).length) sp.append('filters', JSON.stringify(filter.filters))
    return this.axios.get(`/housed/master-catalog/overview?${sp.toString()}`).then(
      (res) =>
        res.data as {
          revenue: number
          cogs: number
          profit: number
          margin: number
          roi: number
          supplier: string
        }[]
    )
  }

  addMasterCatalogItem = async (item: CreateMasterUPCCatalogItemInput) => {
    return this.axios.post(`/housed/master-catalog/item`, item)
  }

  updateMasterCatalogItem = async (id: string, asin: string, updates: Partial<MasterUPCCatalogItem>) => {
    return this.axios.patch<MasterUPCCatalogItem>(`/housed/master-catalog/${id}/${asin}`, updates).then((res) => res.data)
  }

  exportMasterCatalog = async ({ catalog, columnMapping, search, filters, ordering }: { catalog?: string; columnMapping?: { value: string; label: string }[] } & IApiFilter) => {
    const sp = new URLSearchParams()
    if (catalog) sp.append('catalog', catalog)
    if (columnMapping) sp.append('fields', JSON.stringify(columnMapping))
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    const token = await getIdToken()
    sp.append('token', token || '')
    window.open(`${this.axios.defaults.baseURL}/housed/master-catalog/bulk?${sp.toString()}`, '_blank')
  }

  exportAsinEntries = async ({ page = 1, search, filters, ordering }: { page?: number } & IApiFilter) => {
    const sp = new URLSearchParams()
    if (search) sp.append('search', search)
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    const token = await getIdToken()
    sp.append('token', token || '')
    window.open(`${this.axios.defaults.baseURL}/housed/asin-entry/bulk?${sp.toString()}`, '_blank')
  }

  uploadMasterCatalog = async (
    catalogUri: string,
    name: string,
    columnMappings?: Record<
      string,
      {
        key: string
        default?: any
      }
    >
  ) => {
    return this.axios.post(`/housed/master-catalog`, { catalogUri, name, columnMappings })
  }

  getUploadedCatalogs = async () => {
    return this.axios.get(`/housed/master-catalog/catalogs`).then((res) => res.data as string[])
  }

  deleteUploadedCatalog = async (catalog: string) => {
    return this.axios.delete(`/housed/master-catalog`, { params: { name: catalog } })
  }

  getBuyBoxPercentage = async () => {
    return this.axios.get(`/housed/master-catalog/buy-box-percentage`).then((res) => res.data.buyBoxPercentage as number)
  }

  updateBuyBoxPercentage = async (buyBoxPercentage: number) => {
    return this.axios.put(`/housed/master-catalog/buy-box-percentage`, { buyBoxPercentage })
  }

  querySupplierNames = async (search: string) => {
    const sp = new URLSearchParams()
    sp.append('search', search)
    return this.axios.get(`/housed/master-catalog/suppliers?${sp.toString()}`).then((res) => ({ names: res.data as string[], search }))
  }

  getAsinEntry = async ({ page = 1, search, filters, ordering }: { page?: number } & IApiFilter) => {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get(`/housed/asin-entry?${sp.toString()}`).then((res) => ({ ...res.data, search } as { pages: number; items: AsinEntryItem[]; search?: string; next: number | null }))
  }

  addAsinEntry = async (asinEntry: AsinEntryItemInput) => {
    return this.axios.post(`/housed/asin-entry`, asinEntry).then((res) => res.data as AsinEntryItem)
  }

  updateAsinEntry = async (id: string, asinEntry: AsinEntryItemInput) => {
    return this.axios.put(`/housed/asin-entry/${id}`, asinEntry).then((res) => res.data as AsinEntryItem)
  }

  deleteAsinEntry = async (items: string[]) => {
    const sp = new URLSearchParams()
    const joined = items.join(',')
    sp.append('items', joined)
    return this.axios.delete(`/housed/asin-entry?` + sp.toString())
  }

  deleteAsinEntriesBulk = async ({ ids, except, filters, search, ordering }: { ids?: string[]; except?: string[] } & IApiFilter) => {
    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.post(`/housed/asin-entry/delete?${sp.toString()}`, { ids, except })
  }

  uploadAsinEntries = async (uri: string) => {
    return this.axios.post(`/housed/asin-entry/bulk`, { uri })
  }

  asinEntriesPushToHoused = async ({ ids, except, filters, search, ordering }: { ids?: string[]; except?: string[] } & IApiFilter) => {
    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.post(`/housed/asin-entry/to-housed?${sp.toString()}`, { ids, except }).then((res) => {
      if (res.data?.missingAsins?.length) {
        handleError(`These ASINs data: ${res.data.missingAsins.join(', ')} couln't be fetched, so they were not pushed to housed`)
      }
    })
  }

  getAvailableBuyers = async () => {
    return this.axios.get(`/housed/asin-entry/buyers`).then((res) => res.data as string[])
  }

  queryDistributorNames = async (search: string) => {
    const sp = new URLSearchParams()
    sp.append('search', search)
    return this.axios.get(`/housed/asin-entry/distributors?${sp.toString()}`).then((res) => ({ names: res.data as string[], search }))
  }

  getHoused = async ({ page = 1, search, ordering, filters }: { page?: number } & IApiFilter) => {
    // long running
    const base = '' // 'https://us-central1-third-party-profits.cloudfunctions.net/api'

    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))

    const uri = `${base}/housed/housed?${sp.toString()}`
    return this.axios.get(uri).then((res) => ({ ...res.data, search } as { items: HousedAsin[]; search?: string; next: number | null; neverSynced: number }))
  }

  getHousedOverview = async (filter: IApiFilter) => {
    const sp = new URLSearchParams()
    if (filter.search) sp.append('search', filter.search)
    if (filter.ordering) sp.append('orderBy', `${filter.ordering.by}:${filter.ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filter.filters && Object.keys(filter.filters).length) sp.append('filters', JSON.stringify(filter.filters))
    return this.axios.get(`/housed/housed/overview?${sp.toString()}`).then(
      (res) =>
        res.data as {
          revenue: number
          cogs: number
          profit: number
          margin: number
          roi: number
          supplier: string
        }[]
    )
  }

  getHousedById = async (id: string) => {
    return this.axios.get(`/housed/housed/${id}`).then((res) => res.data as HousedAsin)
  }

  getHousedSimilar = async (id: string) => {
    return this.axios.get(`/housed/housed/${id}/similar`).then((res) => res.data as { items: HousedAsin[] })
  }

  addHoused = async (asins: CreateHousedAsinInput[]) => {
    return this.axios.post(`/housed/housed`, { asins })
  }

  updateHoused = async (id: string, housed: HousedAsinInput) => {
    return this.axios.put(`/housed/housed/${id}`, housed).then((res) => res.data as HousedAsin[])
  }

  fetchFromMasterCatalogByUpc = async (override: boolean, { ids, except, filters, search, ordering }: { ids?: string[]; except?: string[] } & IApiFilter) => {
    // long running
    const base = 'https://us-central1-third-party-profits.cloudfunctions.net/api'

    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    sp.append('override', override.toString())
    return this.axios.patch(`${base}/housed/housed/fetch-master/upc?${sp.toString()}`, { ids, except })
  }

  fetchFromMasterCatalogByAsin = async (override: boolean, { ids, except, filters, search, ordering }: { ids?: string[]; except?: string[] } & IApiFilter) => {
    // long running
    const base = 'https://us-central1-third-party-profits.cloudfunctions.net/api'

    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    sp.append('override', override.toString())
    return this.axios.patch(`${base}/housed/housed/fetch-master/asin?${sp.toString()}`, { ids, except })
  }

  deleteHoused = async (id: string) => {
    return this.axios.delete(`/housed/housed/${id}`)
  }

  deleteHousedBulk = async ({ ids, except, uploadKey, filters, search, ordering }: { ids?: string[]; except?: string[]; uploadKey?: string } & IApiFilter) => {
    const sp = new URLSearchParams()
    if (uploadKey) sp.append('uploadKey', uploadKey)
    else {
      search && sp.append('search', search)
      if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
      if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    }
    return this.axios.post(`/housed/housed/delete?${sp.toString()}`, { ids, except })
  }

  generateMSKU = async ({ ids, except, filters, search, ordering }: { ids?: string[]; except?: string[] } & IApiFilter) => {
    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.post(`/housed/housed/msku-generation?${sp.toString()}`, { ids, except })
  }

  uploadHoused = async (
    uri: string,
    columnMappings?: Record<
      string,
      {
        key: string
        default?: any
      }
    >
  ) => {
    // long running
    const base = 'https://us-central1-third-party-profits.cloudfunctions.net/api'

    const endpoint = `${base}/housed/housed/bulk`
    return this.axios.post(endpoint, { uri, columnMappings })
  }

  getHousedUploads = async () => {
    return this.axios.get(`/housed/housed/uploads`).then((res) => res.data as string[])
  }

  exportHoused = async ({ search, filters, ordering }: IApiFilter) => {
    const sp = new URLSearchParams()
    search && sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    const token = await getIdToken()
    sp.append('token', token || '')
    window.open(`${this.axios.defaults.baseURL}/housed/housed/bulk?${sp.toString()}`, '_blank')
  }

  queryHousedSupplierNames = async (search: string) => {
    const sp = new URLSearchParams()
    sp.append('search', search)
    return this.axios.get(`/housed/housed/suppliers?${sp.toString()}`).then((res) => ({ names: res.data as string[], search }))
  }

  getPreferences = async () => {
    return this.axios.get(`/housed/housed/preferences`).then((res) => res.data as IHousedPreferences)
  }

  updatePreferences = async (payload: { shippingCost: IShippingCost; mskuGeneration: MSKUGeneration; housedAsinsBuyBoxPercentage: number | null }) => {
    return this.axios.put(`/housed/housed/preferences`, payload)
  }

  bulkUpdateHoused = async (update: { originalKey?: string; originalValue: any; newKey: string; newValue: any }) => {
    const base = 'https://us-central1-third-party-profits.cloudfunctions.net/api'
    return this.axios.post(`${base}/housed/housed/bulk-update`, update)
  }

  createWorkingPurchaseOrders = async (items: CreateWorkingPOInput[], buyer: string) => {
    const base = 'https://us-central1-third-party-profits.cloudfunctions.net/api'
    return this.axios.post(`${base}/housed/housed/purchase-order`, { items, buyer })
  }
}

const housedAsinsApi = new HousedAsinsApi()

export default housedAsinsApi
