import { useQuery, useQueryClient } from "@tanstack/react-query"
import suppliersApi from "api/suppliers"
import { Dispatch, SetStateAction, useCallback, useMemo } from "react"

const queryFn = async () => {
    return suppliersApi.getNames()
}

const useSupplierNames = () => {
    const {data: supplierNamesUnsorted} = useQuery({
        queryKey: ['supplierNames'],
        queryFn,
    })

    const qc = useQueryClient();

    const setSupplierNames: Dispatch<SetStateAction<string[] | undefined>> = useCallback((updater) => {
        qc.setQueryData<string[] | undefined>(['supplierNames'], updater)
    }, [qc])

    const supplierNames = useMemo(() => {
        const names = supplierNamesUnsorted?.slice().sort()
        return names
    }, [supplierNamesUnsorted])

    return {
        supplierNames,
        setSupplierNames,
    }
}

export default useSupplierNames