import axiosBase, { AxiosError, isAxiosError } from 'axios'
import firebase from '../config/firebase'
import { signOut } from 'firebase/auth'
import { handleError } from 'helpers/errors'
import { subscriptionRequiredBlockerRef } from 'components/SubscriptionRequiredBlocker'
import { nonDemoBlockerRef } from 'components/NonDemoBlocker'
import { getIdToken } from 'utils/getIdToken'

const axios = axiosBase.create({
   baseURL: process.env.NODE_ENV === "development" ? 'http://localhost:5001/third-party-profits/us-central1/api' : 'https://app.thirdpartyprofits.com/api'
})

axios.interceptors.request.use(async (config) => {
    const bearer = await getIdToken()

    config.headers.setAuthorization(`Bearer ${bearer}`)

    return config
})

axios.interceptors.response.use((res) => {
    return res
}, async (err: AxiosError<({ type?: string} & Record<string, unknown>) | string>) => {
    if (err.status === 401) {
      await signOut(firebase.auth)
      return
    } else if (isAxiosError(err) && typeof err.response?.data === "object" && err.response.data.errorCode === "demo_mode") {
      nonDemoBlockerRef.current?.open()
      throw err
    } else if (isAxiosError(err) && typeof err.response?.data === "object" && err.response.data.errorCode === "subscription_required") {
      subscriptionRequiredBlockerRef.current?.open()
      throw err
    } else if (err.response?.data && typeof err.response?.data !== "string" && err.response.data.type) {
      throw err.response.data
    } else {
      handleError(err.response?.data as string || "An unknown error occurred")
      throw err
    }
})

export default axios
