import { AxiosInstance } from 'axios'
import axios from './axios'
import { IApiFilter } from 'types/tableFiltering'
import { ReorderSettings, ReorderSettingsPayload, RestockingItem, RestockingOverview, SalesWeights, StockHistoryPoint } from 'types/restocking'
import { HousedAsin } from 'types/housedAsins'
import { getIdToken } from 'utils/getIdToken'

class RestockingApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  getRestocking = async ({ page = 1, search, ordering, filters }: { page?: number } & IApiFilter) => {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    if (search) sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get(`/restocking?${sp.toString()}`).then((res) => ({ ...res.data, search } as { pages: number; items: RestockingItem[]; search?: string; next: number | null }))
  }

  getRestockingSelectionHoused = async ({ ids, except, search, ordering, filters }: { ids?: string[], except?: string[] } & IApiFilter) => {
    const sp = new URLSearchParams()
    if (ids?.length) sp.append('ids', ids.join(','))
    if (except?.length) sp.append('except', except.join(','))
    if (search) sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get(`/restocking/selection/housed?${sp.toString()}`).then((res) => ({ ...res.data } as { items: HousedAsin[] }))
  }

  getOverview = async ({search, filters}: IApiFilter) => {
    const sp = new URLSearchParams()
    if (search) sp.append('search', search)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get(`/restocking/overview?${sp.toString()}`).then((res) => res.data.overview as RestockingOverview)
  }

  getSupplyChain = async (asin: string) => {
    return this.axios.get(`/restocking/supply-chain/${asin}`).then((res) => res.data.items as RestockingItem[])
  }

  exportRestocking = async (columns: [string, string][], { search, ordering, filters }: IApiFilter) => {
    const sp = new URLSearchParams()
    if (search) sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    sp.append("columns", JSON.stringify(columns))
    const token = await getIdToken()
    if (!token) throw new Error("No token")
    sp.append("token", token)
    const base = this.axios.defaults.baseURL
    const url = `${base}/restocking/export?${sp.toString()}`
    window.open(url, '_blank')
  }

  getWeights = async (sku: string) => {
    return this.axios.get("/restocking/weights", {params: {sku}})
      .then((res) => res.data as {
        weights: SalesWeights
        salesPerPeriod: SalesWeights
        outOfStockDaysPerPeriod: SalesWeights
      })
  }

  updateWeights = async (weights: SalesWeights) => {
    return this.axios.put("/restocking/weights", { weights })
      .then((res) => res.data.weights as SalesWeights)
  }

  getReorderSettings = async (sku: string) => {
    return this.axios.get("/restocking/reorder-settings", {params: {sku}})
      .then((res) => res.data as {
        settings: ReorderSettings
      })
  }

  updateReorderSettings = async (sku: string, data: ReorderSettingsPayload, setAll = false) => {
    return this.axios.put("/restocking/reorder-settings", data, {params: {sku, setAll}})
      .then((res) => res.data.settings as ReorderSettings)
  }

  getStockHistory = async (sku: string) => {
    const localMidnight = new Date()
    localMidnight.setHours(0, 0, 0, 0)
    return this.axios.get("/restocking/stock-history", {params: {sku, until: localMidnight.toISOString()}})
      .then((res) => {
        const history = res.data.history as StockHistoryPoint[]
        return history.map((point) => {
          const date = new Date(localMidnight)
          date.setDate(date.getDate() - point.offset - 1)
          return {
          ...point, date: date.toISOString()
        }
      })
      })
  }
}

const restockingApi = new RestockingApi()

export default restockingApi
