import { AxiosInstance } from 'axios'
import axios from './axios'
import { CatalogDashboard, CatalogItem, IInventoryCostBatch } from 'types/catalog'
import { IApiFilter } from 'types/tableFiltering'

class CatalogApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  getCatalog = async ({ page = 1, search, ordering, filters }: { page?: number } & IApiFilter) => {
    const sp = new URLSearchParams()
    sp.append('page', page.toString())
    if (search) sp.append('search', search)
    if (ordering) sp.append('orderBy', `${ordering.by}:${ordering.direction === 1 ? 'ASC' : 'DESC'}`)
    if (filters && Object.keys(filters).length) sp.append('filters', JSON.stringify(filters))
    return this.axios.get(`/catalog?${sp.toString()}`).then((res) => ({ ...res.data, search } as { pages: number; items: CatalogItem[]; search?: string; next: number | null, dashboard: CatalogDashboard }))
  }

  getSyncState = async () => {
    return this.axios.get('/catalog/synced').then((res) => res.data as { syncedAt: string | null })
  }

  sync = async () => {
    // long running operation, firebase hosting redirect fails after a minute
    const base = 'https://us-central1-third-party-profits.cloudfunctions.net/api'
    const url = `${base}/catalog/sync`
    return this.axios.post(url)
  }

  updateItem = async (id: string, data: { asinCost: number; }) => {
    return this.axios.patch(`/catalog/items/${id}`, data).then((res) => res.data as {
      item: CatalogItem;
      dashboard: CatalogDashboard;
    })
  }

  bulkUpdate = async (data: { asin: string; asinCost?: number, supplier?: string, prepCost?: string }[]) => {
    return this.axios.patch(`/catalog/items/bulk`, data)
  }

  getItemCosts = async (sku: string) => {
    return this.axios.get(`/catalog/items/costs?sku=` + sku).then((res) => res.data as IInventoryCostBatch[])
  }
}

const catalogApi = new CatalogApi()

export default catalogApi
