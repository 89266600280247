import { Tooltip } from '@material-tailwind/react'
import { useQueryClient } from '@tanstack/react-query'
import restockingApi from 'api/restocking'
import Icon from 'assets/icons/iconset'
import Loader from 'components/loaders/Loader'
import Modal from 'components/Modal'
import AreYouSure from 'components/modals/AreYouSure'
import { handleError } from 'helpers/errors'
import useAwaitableModal, { AwaitableModal } from 'hooks/useAwaitableModal'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ReorderSettings, ReorderSettingsPayload, RestockingItem } from 'types/restocking'
import { usNumber } from 'utils/formatting'

const validateSettings = (settingsFull: ReorderSettings) => {
  const { userId: _, sku: __, id: ___, ...settings } = settingsFull
  if (Object.values(settings).some((v) => isNaN(Number(v)))) {
    handleError('All values must be numbers')
    return false
  }
  Object.entries(settings).forEach(([key, value]) => {
    if (+value < 0) {
      handleError(`Weight for period ${key} must be greater than 0`)
      return false
    }
  })
  return Object.fromEntries(Object.entries(settings).map(([key, value]) => [key, +value])) as unknown as ReorderSettingsPayload
}

const ReorderSettingsModal: AwaitableModal<undefined, RestockingItem | undefined> = ({ open, resolve, initialData }) => {
  const sku = initialData?.sku

  const [settings, setSettings] = useState<ReorderSettingsPayload>()
  const [editing, setEditing] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [areYouSure, AreYouSureComponent] = useAwaitableModal(AreYouSure, { description: 'Apply to all products?' })

  const qc = useQueryClient()

  useEffect(() => {
    if (sku) {
      restockingApi.getReorderSettings(sku).then(({ settings }) => {
        setSettings(settings)
      })
    } else {
      setSettings(undefined)
    }
  }, [sku])

  const handleClose = useCallback(() => {
    if (submitting) return
    resolve()
  }, [submitting, resolve])

  const handleSubmit = useCallback(
    (toAll = false) => {
      if (!settings) return
      if (submitting) return
      if (!sku) return
      const validSettings = validateSettings(settings as ReorderSettings)
      if (!validSettings) {
        return
      }
      setSubmitting(true)
      restockingApi
        .updateReorderSettings(sku, validSettings, toAll)
        .then((weights) => {
          qc.invalidateQueries({
            predicate(query) {
              return query.queryKey.at(0) === 'restocking'
            },
          })
          setEditing(false)
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
    [submitting, settings, qc, sku]
  )

  const handleSubmitAll = useCallback(() => {
    areYouSure()
      .then(() => handleSubmit(true))
      .catch(() => {})
  }, [areYouSure, handleSubmit])

  const amazonQty = initialData?.amazonInventory || 0

  const totalLeadTime = settings ? settings?.orderLeadDays + settings?.toWarehouseLeadDays + settings?.productionLeadDays + settings?.toAmazonLeadDays + settings?.bufferStockDays : undefined
  const targetStockTime = (totalLeadTime || 0) + (settings?.targetStockDays || 0)

  const amazonTargetQty = (initialData?.estDailySalesVelocity || 0) * (settings?.targetStockDays || 0)

  const targetStockQty = (initialData?.estDailySalesVelocity || 0) * targetStockTime
  const qtyToReorder = amazonQty < targetStockQty ? Math.min(
    targetStockQty + (totalLeadTime || 0) * (initialData?.estDailySalesVelocity || 0) - amazonQty,
    targetStockQty,
  ) : targetStockQty - amazonQty

  const daysOfInventory = amazonQty / (initialData?.estDailySalesVelocity || 0)
  const daysUntilUnderstocked = daysOfInventory - (totalLeadTime || 0)

  const understockedOn = useMemo(() => {
    if (daysUntilUnderstocked === Infinity) return 'Never'
    const date = new Date()
    date.setDate(date.getDate() + daysUntilUnderstocked)
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
  }, [daysUntilUnderstocked])

  const outOfStockOn = useMemo(() => {
    if (daysOfInventory === Infinity) return 'Never'
    const date = new Date()
    date.setDate(date.getDate() + daysOfInventory)
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })
  }, [daysOfInventory])

  const targetStockQtyLevel = amazonTargetQty
  const leadTimeStockQtyLevel = totalLeadTime ? (initialData?.estDailySalesVelocity || 0) * totalLeadTime : 0
  const totalLeadTimeQty = totalLeadTime ? (initialData?.estDailySalesVelocity || 0) * totalLeadTime : 0

  const sufficientStockQty = targetStockQty

  const minLevel = Math.min(sufficientStockQty, totalLeadTimeQty, targetStockQtyLevel, leadTimeStockQtyLevel, -Math.max(qtyToReorder, 0), amazonQty, 0)
  const maxLevel = Math.max(sufficientStockQty, totalLeadTimeQty, targetStockQtyLevel, leadTimeStockQtyLevel, -Math.max(qtyToReorder, 0), amazonQty, 0)
  const range = maxLevel - minLevel

  const offsetPercentage = (1 - maxLevel / range) * 100
  const leadTimePercentage = -offsetPercentage + (1 - leadTimeStockQtyLevel / range) * 100
  const zeroPercentage = -offsetPercentage + (1 - 0 / range) * 100
  const amazonPercentage = -offsetPercentage + (1 - amazonQty / range) * 100
  const reorderPercentage = -offsetPercentage + (1 + Math.max(qtyToReorder, 0) / range) * 100
  const overstockLimitPercentage = -offsetPercentage + (1 - sufficientStockQty / range) * 100

  const qtyInOverStocked = Math.round(Math.max(amazonQty - sufficientStockQty, 0))
  const qtyInSufficientStock = Math.round(Math.max(amazonQty - totalLeadTimeQty, 0) - Math.max(amazonQty - sufficientStockQty, 0))
  const qtyInUnderStocked = Math.round(amazonQty - (qtyInOverStocked + qtyInSufficientStock))

  const status = useMemo(() => {
    if (!settings) {
      return {
        color: undefined,
        text: '...',
      }
    }

    if (qtyInOverStocked > 0) {
      return {
        color: '#52CBFF',
        text: 'Overstocked',
      }
    }

    if (amazonQty === 0) {
      return {
        color: '#F87171',
        text: 'Out of stock',
      }
    }

    if (daysOfInventory >= 15) {
      return {
        color: '#12B76A',
        text: 'Sufficient stock',
      }
    }
    return {
      color: '#F0A30D',
      text: 'Time to reorder',
    }
  }, [qtyInOverStocked, daysOfInventory, amazonQty, settings])

  return (
    <Modal open={open} close={handleClose}>
      <AreYouSureComponent />
      <div className="w-full rounded-xl max-w-[calc(100vw-4rem)] bg-surface-primary max-h-[calc(100vh-4rem)] h-max min-w-[16rem] overflow-y-auto p-6">
        <div className="flex items-start justify-start pb-4 gap-2">
          <div className="flex flex-col items-start gap-4">
            <span className="text-base text-text-primary font-medium">Days Until Next Reorder</span>
          </div>
        </div>
        <div className="w-full flex gap-4 border border-border-secondary rounded-lg items-center px-4">
          <div className="flex gap-4 items-center w-full py-4">
            <div className="w-12 h-12 rounded-full bg-surface-secondary flex items-center justify-center shrink-0">
              <Icon name="flags/us" className="w-7 h-5" />
            </div>
            <div className="flex flex-col gap-1 grow">
              <span className="text-sm text-text-primary">Title</span>
              <span className="text-xl text-text-primary font-medium">{initialData?.title}</span>
            </div>
          </div>
          <div className="flex flex-col gap-1 grow">
            <span className="text-sm text-text-primary">ASIN</span>
            <span className="text-xl text-text-primary font-medium">{initialData?.asin}</span>
          </div>
          <div className="flex flex-col gap-1 grow">
            <span className="text-sm text-text-primary">SKU</span>
            <span className="text-xl text-text-primary font-medium">{initialData?.sku}</span>
          </div>
        </div>
        <div className="w-full overflow-x-auto">
          <div className="min-w-full grid grid-cols-3 gap-4 items-center py-4 w-max">
            <div className="flex flex-col p-4 border border-border-secondary rounded-lg h-full">
              <div className="flex items-center text-sm text-text-primary">
                <Icon name="Question" className="text-text-secondary w-4 h-4" />
                <span className="grow text-left ml-1 mr-2">In stock</span>
                <span className="text-right">{initialData?.amazonInventory}</span>
              </div>
              <div className="flex items-center mt-3 text-sm text-text-primary">
                <Icon name="Question" className="text-text-secondary w-4 h-4" />
                <span className="grow text-left ml-1 mr-2">Projected Sales</span>
                <span className="text-right">{usNumber(Math.ceil((initialData?.estDailySalesVelocity || 0) * (settings?.targetStockDays || 0)), 0)}</span>
              </div>
              <div className="flex items-center mt-1">
                <Icon name="Question" className="text-text-secondary w-4 h-4" />
                <span className="text-xs text-text-secondary grow text-left ml-1 mr-2">Over Next {settings?.targetStockDays || '...'} days</span>
              </div>
              <div className="flex items-center mt-3 text-sm text-text-primary">
                <Icon name="Question" className="text-text-secondary w-4 h-4" />
                <span className="grow text-left ml-1 mr-2">Est Daily Sales Velocity</span>
                <span className="text-right">{usNumber(Math.ceil(initialData?.estDailySalesVelocity || 0), 0)}</span>
              </div>
            </div>
            <div className="flex flex-col p-4 border border-border-secondary rounded-lg h-full">
              <div className="flex items-center text-sm text-text-primary">
                <Icon name="Question" className="text-text-secondary w-4 h-4" />
                <span className="grow text-left ml-1 mr-2">Current Days Remaining</span>
                <span className="text-right">{usNumber(Math.ceil((initialData?.amazonInventory || 0) / (initialData?.estDailySalesVelocity || 0)), 0)}</span>
              </div>
              <div className="flex items-center mt-3 text-sm text-text-primary">
                <span className="grow text-left mr-2">Stock Status</span>
                <span className="text-center rounded py-1 px-2 text-sm" style={{ color: status.color, backgroundColor: `${status.color}40` }}>
                  {status.text}
                </span>
              </div>
            </div>
            <div className="w-full flex flex-col row-span-2 h-full gap-4 border border-border-secondary rounded-lg items-start p-4">
              <span className="col-span-4 text-base font-medium text-text-primary">Stock level</span>
              <div className="w-full h-full grow bg-white flex rounded-lg p-4 border border-border-primary relative py-4">
                <div className="min-w-max relative h-full flex flex-col [&>span]:transition-all">
                  <span className="invisible">0</span>
                  <span className="invisible">Time to reorder</span>
                  <span className="invisible">Sufficient Stock: {usNumber(Math.ceil(sufficientStockQty), 0)}</span>
                  <span className="invisible">Replenish</span>
                  <span className="invisible">In Stock: {usNumber(amazonQty, 0)}</span>
                  <span
                    className="text-xs text-right text-text-secondary absolute -translate-y-1/2 w-max right-2 bg-white rounded"
                    style={{
                      top: `${leadTimePercentage}%`,
                    }}
                  >
                    Time to Reorder
                    <br />
                  </span>
                  <span className="text-xs text-text-secondary absolute -translate-y-1/2 w-max right-2 bg-white rounded" style={{ top: `${overstockLimitPercentage}%` }}>
                    Sufficient Stock: {usNumber(Math.ceil(sufficientStockQty), 0)}
                  </span>
                  <span className="text-xs text-text-secondary absolute -translate-y-1/2 w-max right-2 bg-white rounded" style={{ top: `${amazonPercentage}%` }}>
                    In Stock: {usNumber(Math.ceil(amazonQty), 0)}
                  </span>
                  <span className="text-xs text-text-secondary absolute -translate-y-1/2 right-2 bg-white rounded" style={{ top: `${zeroPercentage}%` }}>
                    0
                  </span>
                  {!!qtyToReorder && (
                    <span className="text-xs text-text-secondary absolute -translate-y-1/2 w-max right-2 bg-white rounded" style={{ top: `100%` }}>
                      Replenish
                    </span>
                  )}
                </div>
                <div className="w-full h-full relative grow overflow-visible [&>div]:transition-all">
                  <div className="w-full h-px border border-dashed border-emerald-400 absolute left-0 z-[6]" style={{ top: `${overstockLimitPercentage}%` }} />
                  <div className="w-full h-px border border-dashed border-orange-400 absolute left-0 z-[5]" style={{ top: `${leadTimePercentage}%` }} />
                  <div className="w-full h-px border border-dashed border-neutral-400 absolute left-0 z-[7] -translate-y-px" style={{ top: `${amazonPercentage}%` }} />
                  <div className="w-full h-px border border-neutral-600 absolute left-0 z-[10] -translate-y-px" style={{ top: `${zeroPercentage}%` }} />
                  <div
                    className="w-1/2 absolute rounded-t left-1/2 -translate-x-1/2 overflow-hidden"
                    style={{
                      backgroundColor: status.color,
                      top: `${Math.min(amazonPercentage, zeroPercentage)}%`,
                      height: `${Math.abs(amazonPercentage - zeroPercentage)}%`,
                      maxHeight: `${Math.abs(amazonPercentage - zeroPercentage)}%`,
                    }}
                  >
                    <Tooltip
                      placement="left"
                      content={`Overstocked: ${usNumber(qtyInOverStocked, 0)}\nCogs: $ ${usNumber(Math.round(qtyInOverStocked * (initialData?.rollingAvgCogs || 0)), 0)}`}
                      className="bg-slate-900 p-4 rounded-xl max-w-[384px] text-white text-xs whitespace-pre-line z-[1000000000]"
                    >
                      <div
                        className="w-full bg-sky-400"
                        style={{
                          height: `${(qtyInOverStocked * 100) / amazonQty}%`,
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      placement="left"
                      content={`Sufficient Stock: ${usNumber(Math.round(qtyInSufficientStock), 0)}\nCogs: $ ${usNumber(Math.round(qtyInSufficientStock * (initialData?.rollingAvgCogs || 0)), 0)}`}
                      className="bg-slate-900 p-4 rounded-xl max-w-[384px] text-white text-xs whitespace-pre-line z-[1000000000]"
                    >
                      <div
                        className="w-full bg-green-400"
                        style={{
                          height: `${(qtyInSufficientStock / amazonQty) * 100}%`,
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      placement="left"
                      content={`Stock: ${usNumber(qtyInUnderStocked, 0)}\nCogs: $ ${usNumber(Math.round(qtyInUnderStocked * (initialData?.rollingAvgCogs || 0)), 0)}`}
                      className="bg-slate-900 p-4 rounded-xl max-w-[384px] text-white text-xs whitespace-pre-line z-[1000000000]"
                    >
                      <div
                        className="w-full bg-orange-400"
                        style={{
                          height: `${(qtyInUnderStocked * 100) / amazonQty}%`,
                        }}
                      />
                    </Tooltip>
                  </div>
                  <Tooltip
                    placement="left"
                    content={`Replenish: ${usNumber(Math.ceil(qtyToReorder), 0)}\nCogs: $ ${usNumber(Math.ceil(qtyToReorder * (initialData?.rollingAvgCogs || 0)), 0)}`}
                    className="bg-slate-900 p-4 rounded-xl max-w-[384px] text-white text-xs whitespace-pre-line z-[1000000000]"
                  >
                    <div
                      className="w-1/2 absolute rounded-b left-1/2 -translate-x-1/2 bg-red-500"
                      style={{
                        top: `${zeroPercentage}%`,
                        height: `${Math.abs(reorderPercentage - zeroPercentage)}%`,
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="flex flex-col p-4 border border-border-secondary rounded-lg h-full">
              <div className="flex items-center text-sm text-text-primary">
                <span className="grow text-left mr-2 font-medium">Your Settings</span>
                {!submitting && (
                  <button className="button-secondary text-xs !py-1 !px-2 mr-2" onClick={() => handleSubmitAll()}>
                    Apply to all
                  </button>
                )}
                <button className="rounded-full hover:bg-surface-secondary transition-colors p-1">
                  {editing ? (
                    submitting ? (
                      <Loader size={16} />
                    ) : (
                      <Icon name="Check" className="text-text-text-primary" onClick={() => handleSubmit()} />
                    )
                  ) : (
                    <Icon name="Pencil" className="text-text-text-primary" onClick={() => setEditing(true)} />
                  )}
                </button>
              </div>
              <div className="flex items-center text-sm text-text-primary mt-3">
                <span className="grow text-left mr-3.5">Create & Submit Orders</span>
                <input
                  type="number"
                  readOnly={!editing}
                  value={settings?.orderLeadDays || '...'}
                  onChange={(e) => setSettings((old) => old && { ...old, orderLeadDays: e.target.valueAsNumber })}
                  className="w-8 text-right text-text-primary bg-surface-secondary read-only:bg-surface-primary mr-0.5 px-0.5"
                />
                <span className="text-right">days</span>
              </div>
              <div className="flex items-center text-sm text-text-primary mt-2">
                <span className="grow text-left mr-3.5">Lead Time To Warehouse</span>
                <input
                  type="number"
                  readOnly={!editing}
                  value={settings?.toWarehouseLeadDays || '...'}
                  onChange={(e) => setSettings((old) => old && { ...old, toWarehouseLeadDays: e.target.valueAsNumber })}
                  className="w-8 text-right text-text-primary bg-surface-secondary read-only:bg-surface-primary mr-0.5 px-0.5"
                />
                <span className="text-right">days</span>
              </div>
              <div className="flex items-center text-sm text-text-primary mt-2">
                <span className="grow text-left mr-3.5">Average Days Of Production</span>
                <input
                  type="number"
                  readOnly={!editing}
                  value={settings?.productionLeadDays || '...'}
                  onChange={(e) => setSettings((old) => old && { ...old, productionLeadDays: e.target.valueAsNumber })}
                  className="w-8 text-right text-text-primary bg-surface-secondary read-only:bg-surface-primary mr-0.5 px-0.5"
                />
                <span className="text-right">days</span>
              </div>
              <div className="flex items-center text-sm text-text-primary mt-2">
                <span className="grow text-left mr-3.5">Lead Time To Amazon</span>
                <input
                  type="number"
                  readOnly={!editing}
                  value={settings?.toAmazonLeadDays || '...'}
                  onChange={(e) => setSettings((old) => old && { ...old, toAmazonLeadDays: e.target.valueAsNumber })}
                  className="w-8 text-right text-text-primary bg-surface-secondary read-only:bg-surface-primary mr-0.5 px-0.5"
                />
                <span className="text-right">days</span>
              </div>
              <div className="flex items-center text-sm text-text-primary mt-2">
                <span className="grow text-left mr-3.5">Buffer Stock</span>
                <input
                  type="number"
                  readOnly={!editing}
                  value={settings?.bufferStockDays || '...'}
                  onChange={(e) => setSettings((old) => old && { ...old, bufferStockDays: e.target.valueAsNumber })}
                  className="w-8 text-right text-text-primary bg-surface-secondary read-only:bg-surface-primary mr-0.5 px-0.5"
                />
                <span className="text-right">days</span>
              </div>
              <div className="flex items-center text-sm text-text-primary mt-2">
                <span className="grow text-left mr-3.5">Total Lead Time</span>
                <input
                  type="number"
                  readOnly
                  value={totalLeadTime || '...'}
                  onChange={(e) => setSettings((old) => old && { ...old, targetStockDays: e.target.valueAsNumber })}
                  className="w-8 text-right text-text-primary bg-surface-secondary read-only:bg-surface-primary mr-0.5 px-0.5"
                />
                <span className="text-right">days</span>
              </div>
              <div className="flex items-center text-sm text-text-primary mt-2 border-t pt-2">
                <span className="grow text-left mr-3.5">Target Days In Inventory</span>
                <input
                  type="number"
                  readOnly={!editing}
                  value={settings?.targetStockDays || '...'}
                  onChange={(e) => setSettings((old) => old && { ...old, targetStockDays: e.target.valueAsNumber })}
                  className="w-8 text-right text-text-primary bg-surface-secondary read-only:bg-surface-primary mr-0.5 px-0.5"
                />
                <span className="text-right">days</span>
              </div>
            </div>
            <div className="flex flex-col p-4 border border-border-secondary rounded-lg h-full">
              <div className="flex items-center text-sm text-text-primary">
                <span className="grow text-left mr-2 font-medium">Replenishment</span>
              </div>
              <div className="flex items-center text-sm text-text-primary mt-3">
                <span className="grow text-left mr-2">Qty to Replenish</span>
                <span className="text-right">{usNumber(qtyToReorder, 0)}</span>
              </div>
              <div className="flex items-center text-sm text-text-primary mt-2">
                <span className="grow text-left mr-2">Days Till Understocked</span>
                <span className="text-right">{usNumber(Math.floor(daysUntilUnderstocked), 0)}</span>
              </div>
              <div className="flex items-center text-sm text-text-primary mt-2">
                <span className="grow text-left mr-3.5">Latest Day To Reorder</span>
                <span className="text-right">{understockedOn}</span>
              </div>
              <div className="flex items-center text-sm text-text-primary mt-2">
                <span className="grow text-left mr-2">Out Of Stock On</span>
                <span className="text-right">{outOfStockOn}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ReorderSettingsModal
