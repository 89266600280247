import { IndexedDBProps } from "react-indexed-db-hook";
import { IAmazonProductImage } from "types/amazon";

const indexedDB: IndexedDBProps = {
  name: "ThirdPartyProfits",
  version: 2,
  objectStoresMeta: [
    {
      store: "amazon_images",
      storeConfig: { keyPath: "asin", autoIncrement: false },
      storeSchema: [
        { name: "asin", keypath: "asin", options: { unique: true } },
        { name: "created_at", keypath: "created_at", options: { unique: false } },
        { name: "url", keypath: "url", options: { unique: false } },
      ],
    },
  ],
};

export class AmazonImagesIndexedDB {
  static async get(asin: string): Promise<IAmazonProductImage | null> {
    const db = await new Promise<IDBDatabase>((resolve, reject) => {
      const request = window.indexedDB.open(indexedDB.name, indexedDB.version);
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
    const image = await new Promise<IAmazonProductImage | null>((resolve) => {
      const transaction = db.transaction("amazon_images", "readonly");
      const request = transaction.objectStore("amazon_images").get(asin);
      request.onsuccess = () => {
        if (request.result?.created_at && new Date(request.result.created_at) < new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7)) {
          resolve(null);
          return;
        }
        resolve(request.result);
      }
      request.onerror = () => resolve(null);
    });
    return image;
  }

  static async set(image: IAmazonProductImage): Promise<void> {
    const db = await new Promise<IDBDatabase>((resolve, reject) => {
      const request = window.indexedDB.open(indexedDB.name, indexedDB.version);
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
    await new Promise<void>((resolve, reject) => {
      const transaction = db.transaction("amazon_images", "readwrite");
      const request = transaction.objectStore("amazon_images").add({...image, created_at: new Date().toISOString()});
      request.onsuccess = () => resolve();
      request.onerror = () => resolve();
    })
  }
}

export default indexedDB;