import Icon from 'assets/icons/iconset'
import AmazonProductImage from 'components/AmazonProductImage'
import BadgeSelect from 'components/BadgeSelect'
import Tooltip from 'components/Tooltip'
import useTableContext from 'contexts/Table/useTableContext'
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { CatalogDashboard, CatalogItem } from 'types/catalog'
import { usNumber } from 'utils/formatting'
import catalogApi from 'api/catalog'
import { Row } from '@tanstack/react-table'
import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import { RowRenderer } from 'types/tables'
import TR from 'components/tables/TR'

interface props {
  row: Row<CatalogItem>
  setDashboard: Dispatch<SetStateAction<CatalogDashboard | undefined>>
}

const feeMap = {
  ReferralFee: 'Referral Fee',
  VariableClosingFee: 'Variable Closing Fee',
  PerItemFee: 'Per Item Fee',
  FBAFees: 'FBA Fees',
  StorageFee: 'Storage Fee',
}

const Item: FC<props> = ({ row, setDashboard }) => {
  const item = row.original
  const [_asinCost, setAsinCost] = useState<number | undefined>(item.asinCost)
  const { locked, extra } = useTableContext()

  const [updating, setUpdating] = useState(false)

  const supplierNames = extra?.supplierNames || []

  const listingPrice = item.offerPrice || item.salesPrice || 0

  const referralPercentage = item.otherData?.fees?.ReferralFee ? item.otherData?.fees?.ReferralFee / listingPrice : 0

  const formattedFeeBreakdown = Object.entries(item.otherData?.fees || {})
    .map(([type, price]) => {
      if (!price) return null
      // @ts-ignore
      if (!feeMap[type]) return null
      // @ts-ignore
      const formattedType = feeMap[type]
      if (type === 'ReferralFee') {
        return `${formattedType}: $${price} (${(referralPercentage * 100).toFixed(2)}%)`
      } else {
        return `${formattedType}: $${price}`
      }
    })
    .filter(Boolean)
    .join('\n')

  const formattedInventoryBreakdown = Object.entries(item.stockBreakdown)
    .map(([type, qty]) => {
      const formattedType = type
        .replace('Quantity', '')
        .replace(/([A-Z])/g, ' - $1')
        .replace(/^./, (str) => str.toUpperCase())
      return `${formattedType}: ${qty}`
    })
    .join('\n')

  const formattedInvAgeBreakdown = [
    "Inventory Age",
    `0-60 Days: ${item.inventoryAgeBreakdown.invAge0To60Days || 0}`,
    `61-90 Days: ${item.inventoryAgeBreakdown.invAge61To90Days || 0}`,
    `91-180 Days: ${item.inventoryAgeBreakdown.invAge91To180Days || 0}`,
    `181-330 Days: ${item.inventoryAgeBreakdown.invAge181To330Days || 0}`,
    `331-365 Days: ${item.inventoryAgeBreakdown.invAge331To365Days || 0}`,
    `365+ Days: ${item.inventoryAgeBreakdown.invAge365PlusDays || 0}`,
  ].join('\n')

  useEffect(() => {
    setAsinCost(item.asinCost)
  }, [item])

  const update = async (overrides?: any) => {
    if (locked) return
    if (updating) return
    setUpdating(true)
    const asinCost = overrides?.asinCost || _asinCost
    catalogApi
      .updateItem(item.id, {
        asinCost,
      })
      .then(({ dashboard }) => {
        setDashboard(dashboard)
      })
      .finally(() => setUpdating(false))
  }

  const onNumberChange = (updater: Dispatch<SetStateAction<number | undefined>>) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber
    if (isNaN(value)) return updater(undefined)
    updater(value)
  }

  const cellRenderers: RowRenderer<CatalogItem> = {
    image: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <AmazonProductImage asin={item.asin} size={48} className="!w-12 !h-8 object-cover" />
      </td>
    ),
    asin: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {item.asin}
      </td>
    ),
    url: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <a href={`https://www.amazon.com/dp/${item.asin}`} target="_blank" rel="noreferrer">
          <Icon name="AmazonLogo" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
        </a>
      </td>
    ),
    name: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className="max-w-[20rem] truncate">
        {item.name}
      </td>
    ),
    sku: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {item.sku}
      </td>
    ),
    suppliers: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} onBlur={(e) => e.stopPropagation()}>
        <Tooltip text={item.suppliers?.join(', ') || ''} position="left" className="z-[1000]">
          <div className="flex gap-1 items-center w-max">
            {!!item.suppliers?.length && <BadgeSelect selected={item.suppliers[0] || ''} badges={supplierNames || []} onSelect={() => {}} editable={false} />}
            {item.suppliers && item.suppliers?.length > 1 && <span className="text-xs text-blue-500 bg-blue-50 rounded-full py-0.5 px-1">+{item.suppliers.length - 1}</span>}
          </div>
        </Tooltip>
      </td>
    ),
    asinCost: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className="p-1">
        <div className="relative border border-border-primary rounded overflow-hidden">
          <input type="number" readOnly={locked} value={_asinCost} onChange={onNumberChange(setAsinCost)} className="p-2 pl-5" placeholder="0.00" />
          <span className="text-sm text-base-900 p-2 absolute left-0">$</span>
        </div>
      </td>
    ),
    prepCosts: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} onBlur={(e) => e.stopPropagation()}>
        <Tooltip text={item.prepCosts?.join(', ') || ''} position="left" className="z-[1000]">
          <div className="flex gap-1 items-center w-max">
            {!!item.prepCosts?.length && <BadgeSelect selected={item.prepCosts[0] || ''} badges={extra?.prepCosts || []} onSelect={() => {}} editable={false} />}
            {item.prepCosts && item.prepCosts?.length > 1 && <span className="text-xs text-blue-500 bg-blue-50 rounded-full py-0.5 px-1">+{item.prepCosts.length - 1}</span>}
          </div>
        </Tooltip>
      </td>
    ),
    stock: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <Tooltip className="leading-[200%]" text={formattedInventoryBreakdown} position="left-start">
          {usNumber(item.stock, 0)}
        </Tooltip>
      </td>
    ),
    offerPrice: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <Tooltip className="leading-[200%]" text={formattedFeeBreakdown} position="left">
          ${usNumber(listingPrice)}
        </Tooltip>
      </td>
    ),
    totalRevenue: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.totalRevenue)}
      </td>
    ),
    totalCOGs: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.totalStockValue)}
      </td>
    ),
    totalNetProceeds: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        ${usNumber(item.totalNetProceeds)}
      </td>
    ),
    profit: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className={item.profit < 0 ? 'text-red-500' : item.profit > 0 ? 'text-green-500' : ''}>
        ${usNumber(item.profit)}
      </td>
    ),
    totalProfit: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }} className={item.totalProfit < 0 ? 'text-red-500' : item.totalProfit > 0 ? 'text-green-500' : ''}>
        ${usNumber(item.totalProfit)}
      </td>
    ),
    profitShare: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {usNumber(item.profitShare)}%
      </td>
    ),
    listingCreatedAt: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {item.listingCreatedAt ? new Date(item.listingCreatedAt).toLocaleDateString('en-US') : '---'}
      </td>
    ),
    type: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        {item.type}
      </td>
    ),
    historicalDaysOfSupply: (cell) => (
      <td key={cell.id} style={{ ...getCommonPinningStyles(cell.column) }}>
        <Tooltip className="leading-[200%]" text={formattedInvAgeBreakdown} position="left-start">
          {usNumber(item.historicalDaysOfSupply, 0)}
        </Tooltip>
      </td>
    ),
  }

  return (
    <TR className={[updating && 'grayscale'].asClass} onBlur={update}>
      {row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}
    </TR>
  )
}

export default Item
