import {ReactComponent as AmazonLogo} from "./AmazonLogo.svg"
import {ReactComponent as ArrowUp} from "./ArrowUp.svg"
import {ReactComponent as ArrowDown} from "./ArrowDown.svg"
import {ReactComponent as Badge} from "./Badge.svg"
import {ReactComponent as Barcode} from "./Barcode.svg"
import {ReactComponent as CalendarBlank} from "./CalendarBlank.svg"
import {ReactComponent as Calendar} from './Calendar.svg'
import {ReactComponent as CaretDoubleLeft} from "./CaretDoubleLeft.svg"
import {ReactComponent as CaretDoubleRight} from "./CaretDoubleRight.svg"
import {ReactComponent as CaretLeft} from "./CaretLeft.svg"
import {ReactComponent as CaretRight} from "./CaretRight.svg"
import {ReactComponent as CaretDown} from "./CaretDown.svg"
import {ReactComponent as CaretUp} from "./CaretUp.svg"
import {ReactComponent as ChartDonut} from "./ChartDonut.svg"
import {ReactComponent as Check} from "./Check.svg"
import {ReactComponent as CheckSquareOffset} from "./CheckSquareOffset.svg"
import {ReactComponent as CodesandboxLogo} from "./CodesandboxLogo.svg"
import {ReactComponent as CreditCard} from "./CreditCard.svg"
import {ReactComponent as Cube} from "./Cube.svg"
import {ReactComponent as CurrencyCircleDollar} from "./CurrencyCircleDollar.svg"
import {ReactComponent as DownloadSimple} from "./DownloadSimple.svg"
import {ReactComponent as Excel} from "./Excel.svg"
import {ReactComponent as Flag} from "./Flag.svg"
import {ReactComponent as Funnel} from "./Funnel.svg"
import {ReactComponent as Globe} from "./Globe.svg"
import {ReactComponent as ImageSquare} from "./ImageSquare.svg"
import {ReactComponent as Info} from "./Info.svg"
import {ReactComponent as LockSimple} from "./LockSimple.svg"
import {ReactComponent as LockSimpleOpen} from "./LockSimpleOpen.svg"
import {ReactComponent as MagnifyingGlass} from "./MagnifyingGlass.svg"
import {ReactComponent as Money} from "./Money.svg"
import {ReactComponent as Moon} from "./Moon.svg"
import {ReactComponent as Pencil} from "./Pencil.svg"
import {ReactComponent as Plus} from "./Plus.svg"
import {ReactComponent as PnL} from "./P&L.svg"
import {ReactComponent as Refresh} from "./Refresh.svg"
import {ReactComponent as Question} from "./Question.svg"
import {ReactComponent as Schedule} from "./Schedule.svg"
import {ReactComponent as Sliders} from "./Sliders.svg"
import {ReactComponent as SquaresFour} from "./SquaresFour.svg"
import {ReactComponent as Stack} from "./Stack.svg"
import {ReactComponent as Storefront} from "./Storefront.svg"
import {ReactComponent as TaskDone} from "./TaskDone.svg"
import {ReactComponent as TrashSimple} from "./TrashSimple.svg"
import {ReactComponent as UploadSimple} from "./UploadSimple.svg"
import {ReactComponent as Van} from "./Van.svg"
import {ReactComponent as Warning} from "./Warning.svg"
import {ReactComponent as X} from "./X.svg"
import {ReactComponent as PrepCost} from "./PrepCost.svg"
import {ReactComponent as Dollar} from "./Dollar.svg"
import {ReactComponent as Warehouse} from "./Warehouse.svg"
import {ReactComponent as Truck} from "./Truck.svg"
import {ReactComponent as TruckOut} from "./TruckOut.svg"
import {ReactComponent as CompletedPOs} from "./CompletedPOs.svg"
import {ReactComponent as InternalDatabase} from "./InternalDatabase.svg"
import {ReactComponent as Inventory} from "./Inventory.svg"
import {ReactComponent as POConfirmation} from "./POConfirmation.svg"
import {ReactComponent as Restocking} from "./Restocking.svg"
import {ReactComponent as SupplierLeads} from "./SupplierLeads.svg"
import {ReactComponent as SupplierSettings} from "./SupplierSettings.svg"
import {ReactComponent as Suppliers} from "./Suppliers.svg"
import {ReactComponent as WorkingPOs} from "./WorkingPOs.svg"
import {ReactComponent as Buyers} from "./Buyers.svg"
import {ReactComponent as POs} from "./POs.svg"
import { FC } from "react"
import flagComponents from "./flags"

const iconComponents = {
    AmazonLogo,
    ArrowUp,
    ArrowDown,
    Badge,
    Barcode,
    CalendarBlank,
    CaretDoubleLeft,
    CaretDoubleRight,
    CaretLeft,
    CaretRight,
    CaretDown,
    CaretUp,
    ChartDonut,
    Check,
    CheckSquareOffset,
    CodesandboxLogo,
    CreditCard,
    Cube,
    CurrencyCircleDollar,
    DownloadSimple,
    Excel,
    Flag,
    Funnel,
    Globe,
    ImageSquare,
    Info,
    LockSimple,
    LockSimpleOpen,
    MagnifyingGlass,
    Money,
    Moon,
    Pencil,
    Plus,
    Refresh,
    Question,
    Schedule,
    Sliders,
    SquaresFour,
    Stack,
    Storefront,
    TrashSimple,
    UploadSimple,
    Van,
    Warning,
    X,
    Calendar,
    PnL,
    PrepCost,
    Dollar,
    Warehouse,
    Truck,
    TaskDone,
    TruckOut,
    ...flagComponents,
    CompletedPOs,
    InternalDatabase,
    Inventory,
    POConfirmation,
    Restocking,
    SupplierLeads,
    SupplierSettings,
    Suppliers,
    WorkingPOs,
    Buyers,
    POs,
}

export type BlueIconNames = keyof typeof iconComponents

const Icon: FC<React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
    name: BlueIconNames;
}> = ({ title, name, ...props }) => {
    const IconComponent = iconComponents[name]
    return <IconComponent title={title} {...props} />
}

export default Icon
