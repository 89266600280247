import FullpageLoader from 'components/loaders/FullpageLoader'
import useUserContext from 'contexts/User/useUserContext'
import AmazonLoginPage from 'pages/app/Amazon/Login'
import CheckoutSubscriptionPage from 'pages/app/subscriptions/checkout'
import PlansSelectPage from 'pages/app/subscriptions/plans'
import { FC } from 'react'
import { Navigate, Route, Routes } from 'react-router'

const SetupRouter: FC = () => {
  const user = useUserContext()

  const hasSubscription = Object.values(user?.subscriptions || {}).filter(Boolean).length > 0
  const hasSubscriptionSelection = Object.values(user?.subscriptionSelection || {}).filter(Boolean).length > 0

  const hasAmazon = !!user?.amazonConnected

  const setup = hasSubscription && hasAmazon

  if (!user) return <FullpageLoader />

  if (setup) return <Navigate to="/app" replace />

  return (
    <Routes>
      <Route path="amazon" element={!hasAmazon ? <AmazonLoginPage /> : <Navigate to="/app" replace />} />
      <Route path="subscription" element={hasSubscription ? <Navigate to="/app/setup/amazon" replace /> : <CheckoutSubscriptionPage />} />
      <Route
        path="plans"
        element={hasSubscriptionSelection ? <Navigate to="/app/setup/subscription" replace /> : hasSubscription ? <Navigate to="/app/setup/amazon" replace /> : <PlansSelectPage />}
      />
      <Route path="*" element={<Navigate to="/app/setup/subscription" replace />} />
    </Routes>
  )
}

export default SetupRouter
