import { CloseIcon } from 'assets/icons'
import Empty from 'components/Empty'
import Modal from 'components/Modal'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useMemo, useState } from 'react'
import { ExcelUpload } from 'utils/excelUpload'
import { useDropzone } from 'react-dropzone'

interface ColumnMappingEntry {
  key: string
  default: string
}

interface R {
  result: any[] | File
  columnMappings?: Record<string, ColumnMappingEntry>
}

type ExcelUploadType = (typeof ExcelUpload)['prototype']

interface P<T extends ExcelUploadType = ExcelUploadType> {
  withColumnMapping?: boolean
  defaultValueChoices?: Record<string, string[]>
  respondWithFile?: boolean
  template: T
  additionalInfo?: string
}

const BulkUpload: AwaitableModal<R, P> = ({ open, onCancel, resolve, initialData: { template, respondWithFile, additionalInfo, withColumnMapping, defaultValueChoices } }) => {
  const [result, setResult] = useState<any[]>()
  const [file, setFile] = useState<File>()
  const [columnMappings, setColumnMappings] = useState<Record<string, string>>({})
  const [defaultValues, setDefaultValues] = useState<Record<string, string>>({})

  const downloadTemplate = async (e: any) => {
    template.downloadTemplate()
  }

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    if (!file) return
    const fr = new FileReader()
    fr.onload = () => {
      try {
        const r = template.load(fr.result as ArrayBuffer, 'array', respondWithFile ? 5 : undefined, withColumnMapping)
        if (!r) throw new Error('Invalid file')
        setResult(r)
      } catch (err: any) {
        handleError(err)
      }
    }
    fr.readAsArrayBuffer(file as File)
    setFile(file as File)
  }

  const onBulkUpload = async (e: any) => {
    template
      .upload()
      .then((res) => {
        const fr = new FileReader()
        fr.onload = () => {
          try {
            const r = template.load(fr.result as ArrayBuffer, 'array', respondWithFile ? 5 : undefined, withColumnMapping)
            if (!r) throw new Error('Invalid file')
            setResult(r)
          } catch (err: any) {
            handleError(err)
          }
        }
        fr.readAsArrayBuffer(res as File)
        setFile(res as File)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const onResolve = () => {
    const columnMappingsReversed = withColumnMapping ? Object.entries(columnMappings).reduce((acc, [k, v]) => ({ ...acc, [v]: {
      key: k,
      default: defaultValues[k],
    } }), {} as Record<string, ColumnMappingEntry>) : undefined
    Object.entries(defaultValues).forEach(([k, v]) => {
      if (!columnMappingsReversed) return
      const isKey = Object.values(columnMappingsReversed).find((v) => v.key === k)
      if (!isKey) {
        columnMappingsReversed[k] = {
          key: k,
          default: v,
        }
      }
    });
    resolve(!result || !file ? undefined : { result: respondWithFile ? file : result, columnMappings: columnMappingsReversed })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true })

  const templateColumns = useMemo(() => template.schema.map((col) => col.name), [template])
  const unknownColumns = useMemo(() => result && result[0] ? Object.keys(result[0]).filter((k) => !templateColumns.includes(k)) : [], [result, templateColumns])
  const missingColumns = useMemo(() => result && result[0] ? templateColumns.filter((k) => !Object.keys(result[0]).includes(k)) : [], [result, templateColumns])

  const unknownUnmappedColumns = useMemo(() => unknownColumns.filter((c) => !Object.values(columnMappings).includes(c)), [columnMappings, unknownColumns])

  return (
    <Modal open={open} close={onCancel}>
      <div className="bg-white rounded-xl flex-col overflow-hidden divide-y divide-y-slate-200 relative !max-h-[calc(100vh-2rem)] h-full">
        <header className="!mb-0 flex items-center justify-between gap-10">
          <div className="flex flex-col gap-1">
            <span>Bulk Upload</span>
            {!!additionalInfo && <span className="text-base text-slate-500">🛈 {additionalInfo}</span>}
            <span className="text-sm text-slate-500">Make Sure To Have The Data Formatted Correctly (i.e. remove currency symbols), Date Format Should be YYYY-MM-DD</span>
          </div>
          <CloseIcon onClick={onCancel} className="rounded-full hover:bg-slate-200 p-2 cursor-pointer w-10 h-10" />
        </header>
        {result ? (
          <>
            <input {...getInputProps()} className="hidden" />
            <div className="flex w-full h-full overflow-y-auto max-h-[16rem]">
            <div className="grid p-6 gap-4 overflow-x-auto h-max w-full" {...getRootProps()}>
              <h3>Preview</h3>
              <table className="w-full border border-slate-200 rounded-2xl [&_td]:!p-3 [&_th]:!p-3 [&_th]:border">
                <thead>
                  <tr>
                    {Object.keys(result[0]).map((k) => {
                      const columnMap = Object.entries(columnMappings).find(([_, v]) => v === k)
                      const mappedColumn = columnMap?.[0]
                      return (
                      <th key={k} className="text-left">
                    {
                      mappedColumn ? (
                        `${k} >> ${mappedColumn}`
                      ) : (k)
                    }
                      </th>
                    )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {result.slice(0, 10).map((r, row) => (
                    <tr key={`row_${row}`}>
                      {Object.values(r).map((v: any, col) => (
                        <td key={`row_${row}_col_${col}`} className="text-left select-text">{v ? v.toString() : '---'}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              {missingColumns.length > 0 && (
                <div className="grid gap-2">
                  <h3>Missing Columns</h3>
                    <ul className="flex flex-col gap-2">
                      {missingColumns.map((c) => (
                        <li key={c} className="flex gap-4 items-center">
                          <span>{c}</span>
                          <span className="font-medium">&lt;&lt;</span>
                          <select
                            value={columnMappings[c] || ''}
                            className="table-input"
                            onChange={(e) => {
                              setColumnMappings(old => {
                                const newValue = e.target.value
                                if (!newValue) {
                                  const { [c]: _, ...rest } = old
                                  return rest
                                }
                                return { ...old, [c]: newValue }
                              })
                            }}
                          >
                            <option value="">Ignore</option>
                            {columnMappings[c] && <option value={columnMappings[c]}>{columnMappings[c]}</option>}
                            {unknownUnmappedColumns.map((tc) => (
                              <option key={`${c}_${tc}`} value={tc}>
                                {tc}
                              </option>
                            ))}
                          </select>
                          {
                            defaultValueChoices?.[c] ? (
                              <select value={defaultValues[c] || ""} onChange={(e) => setDefaultValues(old => ({...old, [c]: e.target.value}))} className="table-input">
                                <option value="">Set Default Value</option>
                                {defaultValueChoices[c].map((v) => (
                                  <option key={v} value={v}>{v}</option>
                                ))}
                              </select>
                            ) : (
                              <input value={defaultValues[c] || ""} onChange={(e) => setDefaultValues(old => ({...old, [c]: e.target.value}))} className="table-input" placeholder="Set Default Value" />
                            )
                          }
                        </li>
                      ))}
                    </ul>
                </div>
              )}
            </div>
            </div>
            <footer className="flex items-center gap-10 p-6 justify-between">
              <button className="button-destructive" onClick={onCancel}>
                Cancel
              </button>
              <div className="flex gap-4 items-center">
                <button className="button-secondary" onClick={onBulkUpload}>
                  Change
                </button>
                <button className="button-primary" onClick={onResolve}>
                  Continue
                </button>
              </div>
            </footer>
          </>
        ) : (
          <>
            <div className="p-6" {...getRootProps()}>
              <Empty text="No file uploaded" />
            </div>
            <footer className="flex gap-10 items-center p-6 justify-between">
              <button className="button-destructive" onClick={onCancel}>
                Cancel
              </button>
              <div className="flex gap-4 items-center">
                {!withColumnMapping && (
                  <button className="button-secondary" onClick={downloadTemplate}>
                    Download Template
                  </button>
                )}
                <button className="button-primary" onClick={onBulkUpload}>
                  Upload
                </button>
              </div>
            </footer>
          </>
        )}
        {isDragActive && <div className="absolute inset-0 flex items-center justify-center backdrop-blur-xl pointer-events-none">Drop here ...</div>}
      </div>
    </Modal>
  )
}

export default BulkUpload
