import { useQuery } from '@tanstack/react-query'
import housedAsinsApi from 'api/housedAsins'
import { FC } from 'react'
import { MasterUPCCatalogItem } from 'types/housedAsins'
import TableSpanner from 'components/TableSpanner'
import { Row } from '@tanstack/react-table'
import Item from './Item'
import TableLoader from 'components/tables/Loader'

interface props {
  row: Row<MasterUPCCatalogItem>
  asin: string
  open: boolean
  updateItem: (item: Partial<MasterUPCCatalogItem>) => void
}

const SimilarItems: FC<props> = ({ row, asin, open, updateItem }) => {
  const { data: items } = useQuery({
    queryKey: ['master-catalog', 'master-catalog', 'similar', asin],
    queryFn: async () => housedAsinsApi.getMasterCatalog({
      filters: {
        asin: { o: '=', v: asin },
        id: { o: '!=', v: row.original.id },
      }
    }).then((res) => res.items),
    enabled: open,
  })

  if (!open) return null

  return (
    <>
      {!items?.length ? (
        !items ? (
          <TableLoader rows={3} />
        ) : (
          <TableSpanner rows={2}>No other procurement options found</TableSpanner>
        )
      ) : (
        items.map((housed) => <Item key={row.id} row={{ ...row, original: housed }} updateItem={updateItem} nested />)
      )}
    </>
  )
}

export default SimilarItems
