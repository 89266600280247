import { colors } from 'components/BadgeSelect'
import Modal from 'components/Modal'
import { FC, useCallback, useMemo, useState } from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from 'recharts'
import { PieSectorDataItem } from 'recharts/types/polar/Pie'
import { ActiveShape } from 'recharts/types/util/types'

interface props {
  color?: string
  title: string
  formatter: (value: number) => string
  value?: number
  breakdowns?: { supplier: string; value: number }[]
}

const DashboardTile: FC<props> = ({ title, value, color, formatter, breakdowns }) => {
  const loading = value === undefined
  const [open, setOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  const data = useMemo(() => {
    if (!breakdowns) return []
    return breakdowns
      .map((b) => ({
        name: b.supplier || 'N/A',
        originalValue: b.value,
        value: Math.abs(b.value || 0),
      }))
      .filter((b) => b.value)
      .sort((a, b) => b.value - a.value)
  }, [breakdowns])

  const renderActiveShape: ActiveShape<PieSectorDataItem> = useCallback(
    (props: PieSectorDataItem) => {
      const { cx = 0, cy = 0, innerRadius, outerRadius = 0, startAngle, endAngle, stroke } = props
      const dataPoint = data.at(activeIndex)

      return (
        <g>
          <text x={cx} y={cy} fontSize={14} dy={-12} fontWeight={700} textAnchor="middle" fill={stroke}>
            {dataPoint?.name}
          </text>
          <text x={cx} y={cy} fontSize={16} dy={8} fontWeight={600} textAnchor="middle" fill="#222">
            {title}:
          </text>
          <text x={cx} y={cy} dy={36} fontSize={24} fontWeight={700} textAnchor="middle" fill={stroke}>
            {formatter(dataPoint?.originalValue || 0)}
          </text>
          <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={stroke} stroke={stroke} />
          <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 6} outerRadius={outerRadius + 12} fill={stroke} stroke={stroke} />
        </g>
      )
    },
    [activeIndex, data, formatter, title]
  )

  return (
    <div
      onClick={() => (!loading ? setOpen(true) : {})}
      className={
        [
          'w-full h-[92px] flex flex-col rounded-lg bg-surface-primary p-4 gap-2 border border-border-primary',
          loading && 'animate-pulse',
          !loading && breakdowns ? 'cursor-pointer hover:opacity-90 transition-all' : 'cursor-default',
        ].asClass
      }
    >
      <div className="flex gap-1 items-center justify-start">
        <div className="w-3 h-3 rounded-sm" style={{ backgroundColor: color }} />
        <span className="text-sm text-text-secondary">{title}</span>
      </div>
      <div className="h-px bg-border-secondary w-full shrink-0"></div>
      <span className="text-xl font-medium text-text-primary">{loading ? '...' : formatter(value || 0)}</span>
      <Modal close={() => setOpen(false)} open={open}>
        <div className="bg-surface-secondary rounded-xl grid grid-cols-2 !w-[58rem] max-w-[calc(100vw-2rem)] overflow-hidden p-4 gap-4">
          <div className="w-full h-full flex flex-col p-4 bg-surface-primary rounded-lg border border-border-primary drop-shadow-md">
            <span className="text-left text-lg font-bold text-text-primary">{title}</span>
            <div className="h-full aspect-square">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart width={512} height={512}>
                  <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    onMouseEnter={(_, index) => setActiveIndex(index)}
                    data={data}
                    cx="50%"
                    cy="50%"
                    innerRadius={120}
                    outerRadius={160}
                    paddingAngle={2}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % colors.length].text} stroke={colors[index % colors.length].text} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="w-full h-full aspect-square p-4 flex flex-col gap-2 bg-surface-primary rounded-lg border border-border-primary drop-shadow-md">
            <span className="text-left text-lg font-bold text-text-primary">{title} by supplier</span>
            <span className="text-left text-base font-medium text-text-primary pb-2">Total: {formatter(value || 0)}</span>
            <div className="rounded-lg overflow-y-auto h-full">
              <table className="w-full h-max">
                <thead className="sticky top-0 bg-surface-primary">
                  <th>Supplier</th>
                  <th>{title}</th>
                  <th>Percentage</th>
                </thead>
                <tbody onMouseLeave={() => setActiveIndex(0)}>
                  {data.map((d, i) => (
                    <tr key={i} onMouseEnter={() => setActiveIndex(i)} className={i === activeIndex ? 'bg-surface-secondary' : ''}>
                      <td>{d.name}</td>
                      <td>{formatter(d.originalValue || 0)}</td>
                      <td>{(+d.value / (data.reduce((acc, curr) => acc + curr.value, 0)) * 100).toFixed(2)}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DashboardTile
