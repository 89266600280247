import { ColumnDef } from '@tanstack/react-table'
import { Supplier } from 'types/suppliers'

const columnDef: ColumnDef<Supplier>[] = [
  {
    id: 'name',
    header: 'Supplier Name',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'website',
    header: 'Supplier Website',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'accountNumber',
    header: 'Account #',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'storeName',
    header: 'Store Name',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'userName',
    header: 'Username',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'password',
    header: 'Password',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'orderDay',
    header: 'Order Day Of The Week',
    meta: {
      filters: 'array',
    },
  },
  {
    id: 'orderDueBy',
    header: 'Order Due By',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'deliveryDay',
    header: 'Delivery Day Of The Week',
    meta: {
      filters: 'array',
    },
  },
  {
    id: 'leadTime',
    header: 'Lead Time',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'paymentTerms',
    header: 'Payment Terms',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'paymentDate',
    header: 'Payment Due Date',
  },
  {
    id: 'billingAddress',
    header: 'Bill To Address',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'carrier',
    header: 'Carrier',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'shippingAddress',
    header: 'Ship To Address',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'discrepancyTime',
    header: 'Discrepancy Timeframe',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'repName',
    header: 'Sales Rep Name',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'repPhone',
    header: 'Sales Rep Phone',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'repEmail',
    header: 'Sales Rep Email',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'repBirthday',
    header: 'Sales Rep Birthday',
    meta: {
      filters: 'date',
    },
  },
  {
    id: 'creditDept',
    header: 'Credit Department',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'notes',
    header: 'Notes',
    meta: {
      filters: 'string',
    },
  },
]

export default columnDef
