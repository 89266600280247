import ExcelUpload from "../base"

const schema = [
    {
        name: "Name",
        type: "text",
    },
    {
        name: "Website",
        type: "text",
    },
    {
        name: "Email",
        type: "text",
    },
    {
        name: "Phone",
        type: "text",
    },
    {
        name: "Industry",
        type: "text",
    },
    {
        name: "State",
        type: "text",
    },
    {
        name: "Notes",
        type: "text",
    },
    {
        name: "Contact First",
        type: "text",
    },
    {
        name: "Date of Contact",
        type: "text",
    },
    {
        name: "Contact Method",
        type: "text",
    },
    {
        name: "Conversation",
        type: "text",
    },
    {
        name: "Outcome",
        type: "text",
    },
    {
        name: "Date of Next Contact",
        type: "text",
    },
] as const

const excelUpload = new ExcelUpload(schema)

export default excelUpload
