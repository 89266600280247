import api from 'api'
import housedAsinsApi from 'api/housedAsins'
import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { AsinEntryItem } from 'types/housedAsins'
import { BasePrice } from 'types/user'

const UpdatePreferencesModal: AwaitableModal<AsinEntryItem> = ({ open, resolve }) => {
  const [submitting, setSubmitting] = useState(false)
  const [buyBoxPercentage, setBuyBoxPercentage] = useState<number>()
  const [basePrice, setBasePrice] = useState<BasePrice>()
  const navigate = useNavigate()

  const reset = useCallback(() => {
    setBuyBoxPercentage(undefined)
    setBasePrice(undefined)
  }, [])

  const loadPreferences = useCallback(() => {
    housedAsinsApi.getBuyBoxPercentage().then((res) => {
      setBuyBoxPercentage(res * 100)
    })
    api.getBasePricePreferences().then((res) => {
      setBasePrice(res.masterCatalog || 'latest')
    })
  }, [])

  useEffect(() => {
    if (!open) {
      reset()
    } else {
      loadPreferences()
    }
  }, [open, reset, loadPreferences])

  const handleSubmit = async () => {
    if (submitting) return
    if (buyBoxPercentage === undefined) return
    setSubmitting(true)
    Promise.all([housedAsinsApi.updateBuyBoxPercentage(buyBoxPercentage / 100), api.updateBasePricePreferences('masterCatalog', basePrice || 'latest')])
      .then(() => navigate(0))
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal open={open} close={() => resolve()}>
      <div className="w-full max-w-[32rem] rounded-xl max-h-[calc(100%-4rem)] min-w-[16rem] overflow-hidden">
        <div className="flex flex-col bg-surface-primary rounded-t-xl">
          <div className="flex items-start justify-start p-6 pb-4 gap-2 border-b">
            <Icon name="Plus" className="w-6 h-6 text-brand-primary" />
            <div className="flex flex-col items-start gap-1">
              <span className="text-base text-text-primary font-medium">Preferences</span>
              <span className="text-sm text-text-secondary">Add your preferences.</span>
            </div>
          </div>
          <div className={['flex flex-col bg-surface-primary px-6', buyBoxPercentage === undefined && '[&_input]:animate-pulse [&_select]:animate-pulse pointer-events-none'].asClass}>
            <div className="flex flex-col gap-2">
              <p className="text-text-primary font-medium text-base pt-4">Buy Box Percentage</p>
              <div className="flex flex-col gap-1 w-full">
                <div className="flex w-full rounded border border-border-primary">
                  <input
                    type="number"
                    value={buyBoxPercentage}
                    min={0}
                    max={100}
                    onChange={(e) => setBuyBoxPercentage(e.currentTarget.valueAsNumber)}
                    className="py-1 px-3.5 grow rounded-lg"
                    placeholder="10"
                  />
                  <div className="border-l border-l-border-primary p-3 bg-surface-light">%</div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 px-6">
            <p className="text-text-primary font-medium text-base pt-4">Base Price</p>
            <div className="flex flex-col gap-1 w-full">
              <select className="border border-border-primary py-[0.475rem] px-2 rounded-lg outline-none" value={basePrice} onChange={(e) => setBasePrice(e.target.value as BasePrice)}>
                <option value="latest">Latest</option>
                <option value="days30">Last 30 Days</option>
                <option value="days90">Last 90 Days</option>
                <option value="days180">Last 180 Days</option>
                <option value="days365">Last 365 Days</option>
              </select>
            </div>
          </div>
          <div className="flex items-center gap-4 p-4 w-full">
            <button className="button-secondary grow" disabled={submitting} onClick={() => resolve()}>
              Cancel
            </button>
            <button className="button-primary grow" disabled={submitting} onClick={() => handleSubmit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default UpdatePreferencesModal
